import React from 'react';
import GenericTextBox from './GenericTextBox';

class BreakerWithoutImage extends React.Component {
  render() {
    let item = {...this.props.item}
    let options = {...this.props.options}
    let contentEl = []
    // Add text box.
    contentEl.push(<GenericTextBox key='generic-text-box' item={item} options={options} listingItemLink={this.props.listingItemLink}/>)
    
    // Render.
    return (
      <div className={`${item.classes.join(' ')}`}>
        <article
          {...item.article_attributes}
          aria-label={item.title ? item.title : ""}
          className={`${item.article_classes} ${(item.terms && item.terms.indexOf(options.promoted_term)) > -1 ? 'promoted' : ''}`}
          typeof="sioc:Item foaf:Document"
        >
          {contentEl}
        </article>
      </div>
    )
  }
}

export default BreakerWithoutImage;
