const urlParams = (param, value, widgetId, params) => {
  //  this.props.urlParams starts with '?'
  let widgetFound = false
  let widgetParams = (params.startsWith('?') ? params.slice(1) : params)
    .split('&')
    .map(widgetParam => {
      // Ensure the param matches.
      if (widgetParam.startsWith(`${param}-${widgetId}`)) {
        widgetFound = true
        // Don't bother with "all" values.
        if (value === 'all') {
          return null
        }
        else {
          var vals = ""
          // If this is an array value, loop through and concatonate values.
          if (Array.isArray(value)) {
            value.forEach((val) => {
              if (vals) {
                vals += ","
              }
              // Encode the value to ensure "," in values are handled correctly.
              vals += encodeURIComponent(val)
            })
          }
          else {
            // Encode the value to ensure "," in values are handled correctly.
            vals = encodeURIComponent(value)
          }
          // Return the parameter and value.
          return `${param}-${widgetId}=${vals}`
        }
      }
      else {
        return widgetParam
      }
    })
    .filter(widgetParam => !!widgetParam)
  if (!widgetFound) {
    widgetParams.push(`${param}-${widgetId}=${encodeURIComponent(value)}`)
  }
  return '?' + widgetParams.join('&')
}

export default urlParams
