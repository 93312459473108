import React, { useState } from 'react';
import SectionSearchListing from './SectionSearchListing';

export default (props)  => {
  const newQ = new URLSearchParams(window.location.search)
  const [query, setQuery] = useState(window.location.search)
  // Updates the query state.
  const updateQuery = (query) => {
    setQuery(query)
  }

  // Update browser session history with adjusted parameters.
  const updateHistory = (params) => {
    // Build base URL.
    let pageURL = window.location.protocol + "//" + window.location.host + window.location.pathname
    const url = new URL(pageURL);
    // Split param string into individual parameters.
    let split = params.split(/(\?|&)/g)
    let queryString = '?'
    split.forEach(value => {
      // Skip blank/connecting items.
      if (!["", "?", "&"].includes(value)) {
        if (queryString !== "?") {
          queryString += "&"
        }
        let arrayNote = ""
        // Split into key = value pair.
        let reSplit = value.split("=")
        queryString += reSplit[0]+arrayNote+"="+reSplit[1]
      }
    })
    url.search = queryString
    // Update history.
    window.history.pushState({}, '', url);
    // Update query, trigger re-render.
    updateQuery(url.search)
  }
  // Parameters.
  // - URL params.
  let params = window.location.search
  //
  let searchTerm = newQ.get('searchtext-' + props.widgetId) || undefined
  //
  let page = newQ.get('page-' + props.widgetId) || 0

  return (
    <SectionSearchListing
      {...props}
      listParams={{
        searchTerm: searchTerm,
        page: page,
      }}
      query={query}
      urlParams={decodeURI(params)}
      updateQuery={updateQuery}
      updateHistory={updateHistory}
    />
  )
}
