import React, { useState, useEffect, useRef } from 'react';
import ListItem from './ListItem';
import Filters from './Filters';
import Pagination from './Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const htmlDecode = (input) => {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

const Component = props  => {
  const [state, setState] = useState({fetchFinished: false});
  const firstRender = useRef(true);

  let  propsSelectedFilters = Object.values(props.selectedFilters).filter(f => !!f).join(',')

  useEffect((ef) => {
    setState(state => state.fetchFinished ? {fetchFinished: false} : state)
    const offset = props.itemsPerPage * props.page
    let params = []
    if (offset) {
      params.push(`item_offset=${offset}`)
    }
    const filters = propsSelectedFilters
    if (filters.length) {
      params.push(`taxonomy_filter=${filters}`)
    }
    if (props.selectedSortOrder) {
      params.push(`sort=${props.selectedSortOrder}`)
    }
    if (props.selectedEventDateFrom) {
      params.push(`event_from=${props.selectedEventDateFrom}`)
    }
    if (props.selectedEventDateTo) {
      params.push(`event_to=${props.selectedEventDateTo}`)
    }
    if (!firstRender.current) {
      setTimeout(() => {
        let spinnerEl = document.getElementById(`listing-wrapper-${props.widgetId}`)
        spinnerEl.scrollIntoView({behavior: "smooth", block: "start"})
      },100)
    }
    fetch(`/api/oxdrupal_listings/${props.widgetId}/${props.revisionId}?${params.join('&')}`)
      .then( (response) => {
        return response.json();
      })
      .then((json) => {
        if (json.error) {
          console.warn("error: ", json.error);
        }
        else {
          setState({
            options: json.options,
            items: json.items,
            fetchFinished: true
          })
          document.dispatchEvent(new CustomEvent("react_listing_fetch"));
        }
      })
      .catch( (err) => {
        console.warn("Error fetching widget data", err);
      });
    firstRender.current = false
  }, [    // only run useEffect if one of these changes
    props.itemsPerPage, props.widgetId, props.revisionId,
    props.page, propsSelectedFilters,
    props.selectedSortOrder, props.selectedEventDateFrom, props.selectedEventDateTo
  ])

  let footerEl = null;
  if (state.options && state.options.add_footer === '1') {
    if (state.options.footer_format === '0') {
      footerEl =
        <div className={`widget-title ${state.options.footer_alignment}`}>
          <a href={state.options.footer_link_url}>{state.options.footer_link_title}</a>
        </div>
    }
    else {
      let footerClasses = 'grid-footer btn btn-info'
      if (state.options['button_rounding'] === '1') {
        footerClasses += ' button-soft';
      }
      else if (state.options['button_rounding'] === '2') {
        footerClasses += ' button-round';
      }
      footerEl =
        <div className={state.options.footer_alignment}>
          <a href={state.options.footer_link_url} className={footerClasses}>{state.options.footer_link_title}</a>
        </div>
    }
  }
  return <React.Fragment>
    {(props.taxonomyFilters || props.showEventDateFilter || props.displaySortOrder) &&
      <Filters
        selectedFilters={props.selectedFilters}
        taxonomyFilters={props.taxonomyFilters}
        taxonomyPrefilters={props.taxonomyPrefilters}
        showEventDateFilter={props.showEventDateFilter}
        selectedEventDateFrom={props.selectedEventDateFrom}
        selectedEventDateTo={props.selectedEventDateTo}
        displaySortOrder={props.displaySortOrder}
        selectedSortOrder={props.selectedSortOrder}
        widgetId={props.widgetId}
        urlParams={props.urlParams}
        options={state.options}
        updateQuery={props.updateQuery}
        updateHistory={props.updateHistory}
      />
    }
    {!firstRender.current &&
      <div aria-live="polite">
        {state.fetchFinished ? <div className="screen-reader-only">The list was updated</div> : null}
      </div>
    }
    {state.fetchFinished ?
      <React.Fragment>
        {state.items.length ?
          <div className={props.containerClasses} data-items-per-row={state.options['items_per_row']}>
            {state.items.map(
              item => <ListItem key={item.nid}
              options={state.options} item={item}/>
            )}
          </div> :
          <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
            {props.emptyListingMessage ?
              <strong dangerouslySetInnerHTML={{ __html: htmlDecode(props.emptyListingMessage) }} /> :
              <strong>No items found</strong>
            }
          </div>
        }
        {footerEl}
        {state.options && state.options.total_results > props.itemsPerPage && props.displayPager &&
          <Pagination
            widgetId={props.widgetId}
            itemsPerPage={props.itemsPerPage}
            urlParams={props.urlParams}
            totalResults={state.options.total_results || 0}
            page={props.page || 0}
            updateQuery={props.updateQuery}
            updateHistory={props.updateHistory}
          />
        }
      </React.Fragment> :
      <div className='loading' style={{textAlign: 'center', padding: '20px'}}>
        <FontAwesomeIcon
          id={`list-spinner-${props.widgetId}`}
          icon={faSpinner}
          className="fa-pulse fa-3x fa-fw"
          role="presentation"
          alt="Loading listings"
        />
        <div>Loading listings...</div>
      </div>
    }
  </React.Fragment>
}

export default Component
