import React from 'react';
import Select from 'react-select'
import urlParams from '../utils/urlParams';

export default props  => {
    const filtersExpanded = props.filtersExpanded
    const availableTypesOptions = props.filters.availableTypes
        ? Object.keys(props.filters.availableTypes).map( key => ({ value: key, label: props.filters.availableTypes[key] }))
        : []
    const availableSeriesOptions = props.filters.availableSeries
        ? Object.keys(props.filters.availableSeries).map( key => ({ value: key, label: props.filters.availableSeries[key] }))
        : []
    const yearsOptions = props.filters.years
        ? props.filters.years.map( item => ({ value: item, label: item }))
        : []
    const keywordsOptions = props.filters.keywords
        ? props.filters.keywords.map( item => ({ value: item, label: item }))
        : []
    const authorsOptions = props.filters.authors
        ? Object.values(props.filters.authors).map( item => ({ value: item, label: item }))
      : []
    // Check types against available options, as these are not filtered on label.
    const selectedTypes = []
    if (props.filters.availableTypes && props.filter_types) {
      props.filter_types.forEach(function(val) {
        selectedTypes.push({
          "label": props.filters.availableTypes[val.value],
          "value": val.value
        })
      })
    }
  // Check series against available options, as these are not filtered on label.
    const selectedSeries = []
    if (props.filters.availableSeries && props.filter_series) {
      props.filter_series.forEach(function(val) {
        selectedSeries.push({
          "label": props.filters.availableSeries[val.value],
          "value": val.value
        })
      })
    }

    var containsWP = false;
    if (Array.isArray(selectedTypes) && selectedTypes.length) {
      selectedTypes.forEach(function (type) {
        if (type.value === 'c-report') {
          containsWP = true;
        }
      });
    }

   const seriesFilterDisabled = (
     (Array.isArray(props.filtered_types) && props.filtered_types.length) ||
     !containsWP) ? true : false;

    return (<React.Fragment>
        <div id={"filter_symplectic_pubs-" + props.widgetId}>
                <div id="publications-filter-toggle-container">
                    <a href="/" data-toggle="collapse" data-target={"#filters-" + props.widgetId} id={"filter-toggle-" + props.widgetId}
                        className={filtersExpanded ? "" : "collapsed"} aria-expanded={filtersExpanded ? "true" : "false"}
                        onClick={ev => ev.preventDefault()}>
                        {filtersExpanded ? "hide filters" : "show filters"} <span className={`fas fa-chevron-${filtersExpanded ? 'down' : 'left'}`}></span>
                    </a>
                </div>
                <div className={filtersExpanded ? "collapse in" : "collapse"} id={"filters-" + props.widgetId}
                    aria-expanded={filtersExpanded ? "true" : "false"} style={{height: filtersExpanded ? 'auto' : '0px'}}>
                    <div className="row">
                      <fieldset>
                        <div className="col-md-2">
                          <legend className='screen-reader-only'>Curation</legend>
                        </div>
                        <div className="col-md-10" id={"curation-" + props.widgetId}>
                            <div className="col-md-3">
                                <input type="radio" id={"rdo_selected_pubs-" + props.widgetId} name={`widget_limit_to_favourites-${props.widgetId}`} value="1"
                                    disabled={!props.fetchFinished}
                                    checked={props.widget_limit_to_favourites === '1'}
                                    onChange={ ev => {
                                        if (props.widget_limit_to_favourites !== '1') {
                                            let params = urlParams(`widget_limit_to_favourites`, 1, props.widgetId, props.urlParams)
                                            params = urlParams('page', '0', props.widgetId, params)
                                            props.updateHistory(params);
                                        }
                                    }} />
                                <label htmlFor={"rdo_selected_pubs-" + props.widgetId}>selected publications</label>
                            </div>
                            <div className="col-md-3">
                                <input type="radio" id={"rdo_all_pubs-" + props.widgetId} name={`widget_limit_to_favourites-${props.widgetId}`} value="0"
                                    checked={props.widget_limit_to_favourites !== '1'}
                                    onChange={ ev => {
                                        if (props.widget_limit_to_favourites === '1') {
                                            let params = urlParams(`widget_limit_to_favourites`, 0, props.widgetId, props.urlParams)
                                            params = urlParams('page', '0', props.widgetId, params)
                                            props.updateHistory(params);
                                        }
                                    }} />
                                <label htmlFor={"rdo_all_pubs-" + props.widgetId}>all publications</label>
                            </div>
                        </div>
                      </fieldset>
                    </div>
                    <fieldset className='chosen-fields'>
                        <legend className='sr-only'>
                            To remove a selected option, use arrow keys to highlight the option and use "Backspace" to remove the highlighted option.
                        </legend>
                        <div className="row">
                            <div className="col-md-2"><label htmlFor={"symplectic-filter-keywords-" + props.widgetId}>keywords</label></div>
                            <div className="col-md-10">
                                <Select isMulti options={keywordsOptions}
                                    inputId={"symplectic-filter-keywords-" + props.widgetId}
                                    aria-label="select keywords"
                                    styles={{placeholder: (provided, state) => ({ ...provided, color: '#444'})}}
                                    isDisabled={!props.fetchFinished || keywordsOptions.length < 1}
                                    placeholder={keywordsOptions.length < 1 ? 'No filters available' : 'Select...'}
                                    value={props.filter_keywords}
                                    onChange={ev => {
                                        let params = urlParams(
                                        'filter_keywords',
                                        ev ?
                                            ev.map(item => item.label) :
                                            [],
                                        props.widgetId,
                                        props.urlParams,
                                        )
                                        params = urlParams('page', '0', props.widgetId, params,)
                                        props.updateHistory(params);
                                    }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2"><label htmlFor={"symplectic-filter-author-" + props.widgetId}>author</label></div>
                            <div className="col-md-10">
                                <Select isMulti options={authorsOptions}
                                    inputId={"symplectic-filter-author-" + props.widgetId}
                                    aria-label="select author"
                                    styles={{placeholder: (provided, state) => ({ ...provided, color: '#444'})}}
                                    isDisabled={!props.fetchFinished || authorsOptions.length < 1}
                                    placeholder={authorsOptions.length < 1 ? 'No filters available' : 'Select...'}
                                    value={props.filter_authors}
                                    onChange={ev => {
                                    let params = urlParams(
                                        'filter_authors',
                                        ev ?
                                        ev.map(item => item.label) :
                                        [],
                                        props.widgetId,
                                        props.urlParams,
                                    )
                                    params = urlParams('page', '0', props.widgetId, params,)
                                    props.updateHistory(params);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2"><label htmlFor={"symplectic-filter-pub-year-" + props.widgetId}>publication year</label></div>
                            <div className="col-md-10">
                                <Select isMulti options={yearsOptions}
                                    inputId={"symplectic-filter-pub-year-" + props.widgetId}
                                    aria-label="select publication year"
                                    styles={{placeholder: (provided, state) => ({ ...provided, color: '#444'})}}
                                    isDisabled={!props.fetchFinished || yearsOptions.length < 1}
                                    placeholder={yearsOptions.length < 1 ? 'No filters available' : 'Select...'}
                                    value={props.filter_years}
                                    onChange={ev => {
                                        let params = urlParams(
                                            'filter_years',
                                            ev ?
                                                ev.map(item => item.label) :
                                                [],
                                            props.widgetId,
                                            props.urlParams,
                                        )
                                        params = urlParams('page', '0', props.widgetId, params,)
                                        props.updateHistory(params);
                                    }}
                                />
                            </div>
                        </div>
                        {!props.hidePubTypeFilter &&
                            <div className="row">
                                <div className="col-md-2"><label htmlFor={"symplectic-filter-pub-type-" + props.widgetId}>publication type</label></div>
                                <div className="col-md-10">
                                    <Select isMulti options={availableTypesOptions}
                                        inputId={"symplectic-filter-pub-type-" + props.widgetId}
                                        aria-label="select publication type"
                                        styles={{placeholder: (provided, state) => ({ ...provided, color: '#444'})}}
                                        isDisabled={!props.fetchFinished || availableTypesOptions.length < 1}
                                        placeholder={availableTypesOptions.length < 1 ? 'No filters available' : 'Select...'}
                                        value={selectedTypes}
                                        onChange={ev => {
                                            let params = urlParams(
                                                'filter_types',
                                                ev ?
                                                    ev.map(item => item.value) :
                                                    [],
                                                props.widgetId,
                                                props.urlParams,
                                            )
                                            params = urlParams('page', '0', props.widgetId, params,)
                                            props.updateHistory(params);
                                        }} />
                                    </div>
                                </div>
                        }
                        {!seriesFilterDisabled &&
                        <div className="row">
                            <div className="col-md-2"><label htmlFor={"symplectic-filter-pub-series-" + props.widgetId}>series title</label></div>
                            <div className="col-md-10">
                                <Select isMulti options={availableSeriesOptions}
                                    inputId={"symplectic-filter-pub-series-" + props.widgetId}
                                    aria-label="select series"
                                    styles={{placeholder: (provided, state) => ({ ...provided, color: '#444'})}}
                                    isDisabled={!props.fetchFinished || seriesFilterDisabled || availableSeriesOptions.length < 1}
                                    placeholder={availableSeriesOptions.length < 1 ? 'No filters available' : 'Select...'}
                                    value={selectedSeries}
                                    onChange={ev => {
                                        let params = urlParams(
                                            'filter_series',
                                            ev ?
                                                ev.map(item => item.value) :
                                                [],
                                            props.widgetId,
                                            props.urlParams,
                                        )
                                        params = urlParams('page', '0', props.widgetId, params,)
                                        props.updateHistory(params);
                                    }}
                                />
                            </div>
                        </div>
                        }
                    </fieldset>
                </div>
        </div>
    </React.Fragment>)
}
