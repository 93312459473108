import React from 'react';
import StackedTextBox from './StackedTextBox';

class RowWithSeparatorPortrait extends React.Component {
  render() {
    let item = {...this.props.item}
    let options = {...this.props.options}
    let contentEl = []
    // Add info box.
    if (item.content_type === "oxdrupal_event") {
      contentEl.push(
        <div key= 'listing-item-info-box-wrapper' className="listing-item-info-box-wrapper">
          <div className="listing-item-info-box">
            {(item.event_date && item.formatted_event_date && item.formatted_event_date.oxdrupal_teaser_date) || ''}
            <div className="clearfix"></div>
          </div>
        </div>
      )
    }
    // Add text box.
    contentEl.push(
      <div key='text-box' className={`text-box-and-chevron-wrapper ${!item.event_date ? 'no-info-box' : ''}`}>
        <div className="text-box">
          <StackedTextBox key='stacked-text-box' item={item} options={options} listingItemLink={this.props.listingItemLink}/>
        </div>
        <div className="list-style-arrow-wrapper">
          {
            item.link_url &&
            <div className="list-style-arrow fas fa-2x fa-chevron-circle-right"></div>
          }
        </div>
      </div>
    )

    // Render.
    return (
      <div className={`${item.classes.join(' ')}`}>
        <article
          className={item.article_classes}
          aria-label={item.title ? item.title : ""}
          about={item.guid}
          typeof="sioc:Item foaf:Document"
        >
          <div className="text-box-wrapper">
            {contentEl}
          </div>
        </article>
        <div className="listing-spacer listing-item-spacer"></div>
      </div>
    )
  }
}

export default RowWithSeparatorPortrait;
