import React from 'react';
import StackedTextBox from './StackedTextBox';

class CardNoImage extends React.Component {
  render() {
    let item = {...this.props.item}
    let options = {...this.props.options}
    let contentEl = []
    let itemClass = 'card'
    // Add text box.
    contentEl.push(<StackedTextBox key='stacked-text-box' item={item} options={options} itemclassName={itemClass} listingItemLink={this.props.listingItemLink}/>)
    // Add call to action.
    if (item.call_to_action) {
      let alignClass = ''
      if (options.card_text_alignment === 'text-left' && item.article_classes.indexOf('borderoff')) {
        alignClass = 'text-right'
      }
      else {
        alignClass = 'text-center'
      }
      contentEl.push(
        <div key='call-to-action-wrapper' className={`call-to-action-wrapper ${alignClass}`}>
          {item.cta_or_label === "call_to_action" ?
            <a
              className="btn btn-primary btn-cta hvr-icon-grow"
              href={item.call_to_action.url}
              aria-label={item.call_to_action.title ? `Link to ${item.call_to_action.url }` : null}
              target={ item.call_to_action.attributes.target ? item.call_to_action.attributes.target : null}
            >
              {item.call_to_action && item.call_to_action.title}
            </a> :
            <div className="label-cta">
              {item.call_to_action && item.call_to_action.title}
            </div>
          }
        </div>
      )
    }

    // Render.
    return (
      <div className={`${item.classes.join(' ')}`}>
        <article
          {...item.article_attributes}
          aria-label={item.title ? item.title : ""}
          className={`${item.article_classes} ${(item.terms && item.terms.indexOf(options.promoted_term)) > -1 ? 'promoted' : ''}`}
          typeof="sioc:Item foaf:Document"
        >
          {contentEl}
        </article>
        <div className="listing-spacer listing-item-spacer"></div>
      </div>
    )
  }
}

export default CardNoImage;
