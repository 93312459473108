import React, { useState, useEffect, useRef } from 'react';
import ListItem from './ListItem';
import Filters from './Filters';
import Pagination from './Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import urlParams from '../utils/urlParams';

export default props  => {
    const [state, setState] = useState({fetchFinished: false, options: {}});
    const [textFilter, setTextFilter] = useState({text: '', typingTimeout: 0});
    const firstRender = useRef(true);

    let params = []

    Object.keys(props).forEach( key => {
        if (key === 'publicationType') { 
            params.push('widget_publication_type='+ props[key]);
        }
    });
    Object.keys(props.listParams).forEach( key => {
        if (Array.isArray(props.listParams[key])) {
            if (props.listParams[key].length > 0) {
                params.push(props.listParams[key].map( v => `${key}[]=${v.label}`).join('&'))
            }
        }
        else {
            if (props.listParams[key] !== undefined && props.listParams[key] !== null && props.listParams[key] !== '') {
                params.push(`${key}=${props.listParams[key]}`)
            }
        }
    }); 
    // Identify whether we are on the "More" page.
    if (window.location.pathname.indexOf("symplectic/publications/list") !== -1) {
        params.push('publications_more_page=1')
    }

    const paramsStr = params.join('&');
   
    useEffect((ef) => {
        setState(state => state.fetchFinished ? {fetchFinished: false, options: state.options} : state)
        const abortController = new AbortController();
        fetch(`/api/symplectic/publications/${props.widgetId}/${props.revisionId}/${props.nodeId}?${paramsStr}`,
                { signal: abortController.signal })
            .then( (response) => {
                return response.json();
            })
          .then((json) => {
                if (json.error) {
                    console.warn("error: ", json.error);
                }
                else {
                    setState({
                        options: json.options || {},
                        filters: json.filters || {},
                        items: json.items,
                        fetchFinished: true
                    })
                    const script = document.createElement("script");
                    script.src = "https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js";
                    script.async = true;
                    document.body.appendChild(script);
                }
            })
          .catch( (err) => {
              console.warn("Error fetching widget data", err);
          });
          return () => {abortController.abort()}
    }, [    // only run useEffect if one of these changes
        paramsStr, props.widgetId, props.nodeId, props.revisionId
    ])
    useEffect((ef) => {
        if (!firstRender.current) {
            setTimeout(() => {
                let spinnerEl = document.getElementById(`publications-listing-wrapper`)
                spinnerEl.scrollIntoView({behavior: "smooth", block: "start"})
            }, 100)
        }
        firstRender.current = false
    }, [props.widgetId, props.listParams.page])

    let footerEl = null;
    if (state.options && state.options.add_footer === '1') {
        if (state.options.footer_format === '0') {
            footerEl = <div className={`widget-title ${state.options.footer_alignment}`}>
                <a href={state.options.footer_link_url}>{state.options.footer_link_title}</a>
            </div>
        } else {
            let footerClasses = 'grid-footer btn btn-info'
            if (state.options['button_rounding'] === '1') {
                footerClasses += ' button-soft';
            } else if (state.options['button_rounding'] === '2') {
                footerClasses += ' button-round';
            }
            footerEl = <div className={state.options.footer_alignment}>
                <a href={state.options.footer_link_url} className={footerClasses}>{state.options.footer_link_title}</a>
            </div>
        }
    }

    const listEl = (
        <div className="paragraphs-item-symplectic-publications-listing">
            <div id="publications-listing" className="publication-listing publications-listing view-content row">
                {state.items && state.options.totalItems > 0
                    ? state.items.map( (item, idx) => (
                        <ListItem key={item.publication.nid} item={item} loopCounter={idx + 1}
                            showAuthorEditor={props.showAuthorEditor === "1"}
                            widgetId={props.widgetId} options={state.options}/>
                    ))
                    : <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12"><strong>No publications have been listed.</strong></div>
                }
            </div>
        </div>
    )
    return <React.Fragment>
        <div className="row">
            <div id="publications-listing-wrapper" className="col-sm-12 col-md-12">
                {props.isBriefList !== "1" &&
                    <React.Fragment>
                        <h1>{!!props.listParams.widget_page_title && props.listParams.widget_page_title + ": "}
                            List of publications
                        </h1>
                        <div className="row">
                            <div className="col-md-4">
                                {state.options.totalItems > 0 &&
                                    <React.Fragment>
                                        Showing <strong className="number-of-items-start">{state.options.from}</strong> to <strong className="number-of-items-last">{state.options.to}</strong> of <strong className="number-of-items-total">{state.options.totalItems}</strong> publications
                                    </React.Fragment>
                                }
                            </div>
                            <div className="col-md-8" style={{visibility: 'hidden'}}>
                                <label htmlFor="items_per_page">items per page</label>
                                <select id="items_per_page" name="max_publications_to_display"
                                    disabled={!state.fetchFinished}
                                    value={props.listParams.max_publications_to_display}
                                    onChange={ev => {
                                        let params = urlParams('max_publications_to_display', ev.target.value, props.widgetId, props.urlParams)
                                        params = urlParams('page', '0', props.widgetId, params)
                                        props.updateHistory(params);
                                    }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="1000">All</option>
                                </select>
                            </div>
                            <div id="freetext_filter_symplectic_pubs">
                                <div className="row">
                                    <div className="col-md-2"><label htmlFor={"subject-"+ props.widgetId}>subject</label></div>
                                    <div className="col-md-9" id="publications-freetext-filter-wrapper">
                                        <input id={"subject-"+ props.widgetId} type="text" className="search form-control form-input form-filter" placeholder="Search"
                                            disabled={!state.fetchFinished}
                                            value={textFilter.text}
                                            onChange={ev => {
                                                let textValue = ev.target.value
                                                if (textFilter.typingTimeout) {
                                                   clearTimeout(textFilter.typingTimeout);
                                                }
                                                setTextFilter({
                                                   text: textValue,
                                                   typingTimeout: setTimeout(() => {
                                                        let params = urlParams('freetext_filter', (textValue.length > 2 ? textValue : ''), props.widgetId, props.urlParams)
                                                        params = urlParams('page', '0', props.widgetId, params)
                                                        props.updateHistory(params);
                                                   }, 1000)
                                                });
                                            }} />
                                        <input id="publications-freetext-filter-clear" type="submit" className="form-input" value="Clear"
                                            onClick={() => {
                                                if (textFilter.typingTimeout) {
                                                   clearTimeout(textFilter.typingTimeout)
                                                }
                                                setTextFilter({
                                                   text: '',
                                                   typingTimeout: setTimeout(() => {
                                                        let params = urlParams('freetext_filter', '', props.widgetId, props.urlParams)
                                                        params = urlParams('page', '0', props.widgetId, params)
                                                        props.updateHistory(params);
                                                   }, 0)
                                                });
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <Filters urlParams={props.urlParams}
                                widgetId={props.widgetId}
                                fetchFinished={state.fetchFinished}
                                filtersExpanded={props.filtersExpanded === "true"}
                                hidePubTypeFilter={props.hidePubTypeFilter === "1"}
                                filters={state.filters || {}}
                                widget_limit_to_favourites={props.listParams.widget_limit_to_favourites}
                                filter_keywords={props.listParams.filter_keywords}
                                filter_authors={props.listParams.filter_authors}
                                filter_years={props.listParams.filter_years}
                                filter_types={props.listParams.filter_types
                                    ? props.listParams.filter_types
                                    : props.listParams.widget_publication_type
                                }
                                filter_series={props.listParams.filter_series
                                    ? props.listParams.filter_series
                                    : []
                                }
                                updateQuery={props.updateQuery}
                                updateHistory={props.updateHistory}
                            />
                        </div>
                        <div style={{padding: '10px'}}/>
                    </React.Fragment>
                }
                {!firstRender.current &&
                    <div aria-live="polite">
                        {state.fetchFinished ? <div className="screen-reader-only">The list was updated</div> : null}
                    </div>
                }
                {state.fetchFinished
                    ? <React.Fragment>
                        {state.items.length
                            ? listEl
                            : <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12"><strong>No items found</strong></div>}
                        {footerEl}
                        {props.isBriefList !== '1' && state.options && state.options.totalItems > state.options.itemsPerPage &&
                            <Pagination
                                widgetId={props.widgetId}
                                itemsPerPage={state.options.itemsPerPage}
                                urlParams={props.urlParams}
                                totalResults={state.options.totalItems || 0}
                                page={props.listParams.page || 0}
                                updateQuery={props.updateQuery}
                                updateHistory={props.updateHistory}
                            />}
                        {props.isBriefList === '1' && props.showMoreButton === '1' &&
                            <a className="btn btn-info publications-more-button"
                                href={`/symplectic/publications/list/${props.widgetId}/${props.revisionId}/${props.nodeId}/?${props.publicationsMoreButtonParams}`}>
                                More
                            </a>}
                    </React.Fragment> :
                    <div className='loading' style={{textAlign: 'center', padding: '20px'}}>
                        <FontAwesomeIcon id={`list-spinner-${props.widgetId}`}
                            icon={faSpinner} className="fa-pulse fa-3x fa-fw" alt="Loading listings"/>
                        <div>Loading listings...</div>
                    </div>
                }
            </div>
        </div>
    </React.Fragment>
}
