import React from 'react';
import StackedTextBox from './StackedTextBox';

class TileLandscapeNoImage extends React.Component {
  render() {
    let item = {...this.props.item}
    let options = {...this.props.options}

    let contentEl = (
      <article
        {...item.article_attributes}
        aria-label={item.title ? item.title : ""}
        className={`${item.article_classes} ${(item.terms && item.terms.indexOf(options.promoted_term)) > -1 ? 'promoted' : ''}`}
        typeof="sioc:Item foaf:Document"
      >
        <div className="text-box-wrapper">
          <div className="text-box-and-chevron-wrapper no-info-box">
            <div className="text-box">
              <StackedTextBox key='stacked-text-box' item={item} options={options} itemclassName='landscape' listingItemLink={this.props.listingItemLink}/>
            </div>
            {(item.content_type !== "oxdrupal_event" ||
              (item.content_type === "oxdrupal_event" && options.items_per_row <= 2)) &&
              item.link_url &&
              <div className="list-style-arrow-wrapper">
                  <div className="list-style-arrow fas fa-2x fa-chevron-circle-right"></div>
              </div>
            }
          </div>
        </div>
      </article>
    )

    return (
      <div className={`${item.classes.join(' ')}`}>
        {contentEl}
        <div className="listing-spacer listing-item-spacer"></div>
      </div>
    )
  }
}

export default TileLandscapeNoImage;
