import React from 'react';

class GenericTextBox extends React.Component {
  render() {
    let item = {...this.props.item}
    let options = {...this.props.options}
    let textBoxHtml = [];
    let alignClass = ''
    let authorAndPubDate = null
    let textWrapper = []
    // If "Tile" item style -> Set title alignment.
    if (options.item_style_grouping === 'tile') {
      alignClass = options.portrait_text_alignment
      if (alignClass === 'title-center') {
        alignClass = 'text-center'
      }
    }
    // If: Article -> Set Author/Publication date.
    if (item.content_type === "oxdrupal_article") {
      authorAndPubDate = []
      if (item.author && options.display_author) {
        authorAndPubDate = authorAndPubDate.concat([(item.author.trim())])
      }
      if (item.publication_date && !item.hide_publication_date) {
        authorAndPubDate = authorAndPubDate.concat([item.formatted_publication_date.oxdrupal_listing.trim()])
      }
    }
    // If: "Tile: Rollover" -> Add metadata.
    if (options.item_style === "tile_text_rollover") {
      // If: Author/Publication date -> Display.
      if (authorAndPubDate && authorAndPubDate.length > 0) {
        textBoxHtml.push(
          <div key='text-row-1' className="text-row text-row-metadata listing-item-pub-date">
            <div
              className={`teaser-field ${alignClass}`}
              dangerouslySetInnerHTML={{ __html: authorAndPubDate.join(', ')}}
            />
          </div>
        )
      }
      // If: Event -> Display event date.
      if (item.content_type === "oxdrupal_event" && options.display_date!== undefined && !options.display_date) {
        textBoxHtml.push(
          <div key='text-row-2' className="text-row text-row-metadata listing-item-event-date">
            <div className={` ${alignClass}`}>
              {
                item.use_custom_event_date ?
                  item.custom_event_date :
                  (item.event_date ? item.formatted_event_date.oxdrupal_listing : '')
              }
            </div>
          </div>
        )
      }
    }
    // If: Subtype -> Display.
    if (item.category_subtype && (options.display_content_subtype || options.display_style === 'banner')) {
      textBoxHtml.push(
        <div
          key='text-row-3'
          className={`text-row text-subtypes ${alignClass}`}
          dangerouslySetInnerHTML={{ __html: item.category_subtype}}
        />
      )
    }
    // Create link for overlay on text
    let textListingLink = item.link_url ?
      this.props.listingItemLink(item.link_url, item.title, !this.props.renderedLink, item.padlock) :
      "";
    // If: Title -> Display.
    if (item.title) {
      textBoxHtml.push(
        <div key='listing-title' className="listing-title">
          {
            options.item_style_grouping === 'tile' ?
              <h3 className={alignClass} dangerouslySetInnerHTML={{ __html: (item.padlock !== false ? item.padlock : '') + item.title}} /> :
              <h3 dangerouslySetInnerHTML={{ __html: (item.padlock !== false ? item.padlock : '') + item.title}} />
          }
        </div>
      )
    }
    // If: Image gallery -> Add caption.
    if (options.display_style === "image_gallery") {
      if (item.teaser_image_caption) {
        textBoxHtml.push(
          <div key='teaser-field' className="teaser-field text-wrapper">
            <div
              className="teaser-field teaser-text image-caption"
              dangerouslySetInnerHTML={{ __html: item.teaser_image_caption}}
            />
          </div>
        )
      }
    }
    // Else: Teaser text/Tertiary text.
    else {
      // twig: {% set textWrapper %}
      if (item.content_type !== "oxdrupal_person" && item.teaser_text && options.display_listing_text) {
          if (options.item_style_grouping !== 'tile') {
              alignClass = ''
          }
          textWrapper.push(
              <div key='teaser-field-1' className="teaser-field listing-item-teaser-text teaser-text">
                  {item.short_teaser_text
                      ? <React.Fragment>
                          <div className={`shortened-short ${alignClass}`}
                              dangerouslySetInnerHTML={{ __html: item.short_teaser_text}}/>
                          {item.medium_teaser_text &&
                              <div className={`shortened-medium ${alignClass}`}
                                  dangerouslySetInnerHTML={{ __html: item.medium_teaser_text}}/>}
                          {item.long_teaser_text &&
                              <div className={`shortened-long ${alignClass}`}
                                  dangerouslySetInnerHTML={{ __html: item.long_teaser_text}}/>}
                          <div className={`shortened-raw ${alignClass}`}
                              dangerouslySetInnerHTML={{ __html: item.teaser_text}}/>
                      </React.Fragment>
                      : <div className={alignClass}
                          dangerouslySetInnerHTML={{ __html: item.teaser_text}}/>
                  }
              </div>
          )
      }
      // Add tertiary text if set.
      if (options.display_tertiary_text && item.tertiary_text) {
        // Alignment if Tile item style.
        if (options.item_style_grouping === 'tile') {
          if (alignClass === 'title-center') {
            alignClass = 'text-left'
          }
        }
        // Add tertiary text.
        if (item.tertiary_text) {
          textWrapper.push(
            <div key='teaser-field-2' className="teaser-field listing-item-tertiary-text teaser-text">
              {
                item.short_tertiary_text ?
                  <React.Fragment>
                    <div
                      className={`shortened-short ${alignClass}`}
                      dangerouslySetInnerHTML={{ __html: item.short_tertiary_text}}
                    />
                    {
                      item.medium_tertiary_text &&
                      <div
                        className={`shortened-medium ${alignClass}`}
                        dangerouslySetInnerHTML={{ __html: item.medium_tertiary_text}}
                      />
                    }
                    {
                      item.long_tertiary_text &&
                      <div
                        className={`shortened-long ${alignClass}`}
                        dangerouslySetInnerHTML={{ __html: item.long_tertiary_text}}
                      />
                    }
                    <div
                      className={`shortened-raw ${alignClass}`}
                      dangerouslySetInnerHTML={{ __html: item.tertiary_text}}
                    />
                  </React.Fragment> :
                  <div
                    className={alignClass}
                    dangerouslySetInnerHTML={{ __html: item.tertiary_text}}
                  />
              }
            </div>
          )
        }
      }
      // Add author if set.
      if (
        options.item_style_grouping !== "tile" &&
        item.content_type === "oxdrupal_article" &&
        item.author &&
        options.display_author
      ) {
        textWrapper.push(
          <div key='teaser-field-3' className="teaser-field listing-item-teaser-text teaser-text">
            {item.author}
          </div>
        )
      }
      if (textWrapper) {
        textBoxHtml.push(
          <div key='text-wrapper-teaser' className="teaser-field text-wrapper">
            {textWrapper}
          </div>
        )
      }
    }
    // Metadata.
    let metadata = (item.event_location || '').concat(item.person_job_title || '', item.person_job_title || '', item.person_affiliation || '')
    // Text alignment.
    if (options.item_style_grouping === 'tile') {
      alignClass = options.portrait_text_alignment
      if (alignClass === 'title-center') {
        alignClass = 'text-center'
      }
    }
    // Set Event metadata.
    if (
      item.content_type === "oxdrupal_event" &&
      options.item_style !== "tile_text_rollover" &&
      options.display_date !== undefined &&
      !options.display_date
    ) {
      textBoxHtml.push(
        <div key='metadata-event-date' className={`text-row text-row-metadata listing-item-event-date ${alignClass} ff`}>
          {
            options.metadata_labels &&
            options.metadata_labels.start_date &&
            <div className="metadata-label">
              {options.metadata_labels.start_date}
            </div>
          }
          <div className="metadata-data">
            {
              item.use_custom_event_date && item.custom_event_date && item.custom_event_date.trim() !== "" ?
                item.custom_event_date :
                item.event_date &&
                (
                  item.event_date !== item.event_end_date && item.event_end_date ?
                    `${item.formatted_event_date.oxdrupal_listing} - ${item.formatted_event_end_date.oxdrupal_listing}` :
                    item.formatted_event_date.oxdrupal_listing
                )
            }
          </div>
        </div>
      )
    }

    let textRowMetadata = [];
    // Set Event metadata.
    if (item.content_type === "oxdrupal_event" && item.event_location) {
      if (options.metadata_labels && options.metadata_labels.location) {
        textRowMetadata.push(
          <div key='metadata-location-1' className="metadata-label">{options.metadata_labels.location}</div>
        )
      }
      textRowMetadata.push(
        <div key='metadata-location-2' className={`metadata-data ${options.item_style_grouping === 'tile' ? alignClass : ''}`}>
          {item.event_location}
        </div>
      )
    }
    // Set Person metadata.
    if (item.content_type === "oxdrupal_person") {
      if (item.person_job_title) {
        textRowMetadata.push(
          <div key='text-row-metadata-1' className="text-row text-row-metadata listing-item-person-job-title">
            {options.metadata_labels && options.metadata_labels.job_title &&
              <div className="metadata-label">
                {options.metadata_labels.job_title}
              </div>}
            <div className="metadata-data">{item.person_job_title}</div>
          </div>
        )
      }
      if (item.person_affiliation) {
        textRowMetadata.push(
          <div key='text-row-metadata-2' className="text-row text-row-metadata listing-item-person-affiliation">
            {options.metadata_labels && options.metadata_labels.affiliation &&
              <div className="metadata-label">
                {options.metadata_labels.affiliation}
              </div>}
            <div className="metadata-data">{item.person_affiliation}</div>
          </div>
        )
      }
    }
    // Set Article metadata.
    if (item.content_type === "oxdrupal_article") {
      if (!item.hide_publication_date && options.item_style !== 'tile_text_rollover') {
        textRowMetadata.push(
          <div key='metadata-pub-date' className="text-row text-row-metadata listing-item-pub-date">
            {
              options.metadata_labels &&
              options.metadata_labels.publication_date &&
              <div className="metadata-label">
                {options.metadata_labels.publication_date}
              </div>
            }
            {
              authorAndPubDate &&
              <div
                className="metadata-data"
                dangerouslySetInnerHTML={{ __html: authorAndPubDate.join(', ')}}
              />
            }
          </div>
        )
      }
    }
    // Add metadata.
    textBoxHtml.push(
      <div key='text-row-metadata' className={`text-row text-row-metadata ${ (metadata.trim() && !item.hide_publication_date) ? alignClass : ''}`}>
        {textRowMetadata}
      </div>
    )
    // Add call to action.
    if ((options.item_style_grouping !== "tile" || options.item_style === "tile_landscape_50_50") && item.call_to_action) {
      textBoxHtml.push(
        <div key='call-to-action-wrapper' className="call-to-action-wrapper">
          {item.cta_or_label === "call_to_action" ?
            <a
              className="btn btn-primary btn-cta hvr-icon-grow"
              href={item.call_to_action.url}
              target={item.call_to_action.attributes.target}
              aria-label={item.call_to_action ? `Link to ${item.call_to_action.url }` : null}
            >
              {item.call_to_action && item.call_to_action.title}
            </a> :
            <div className="label-cta">{item.call_to_action && item.call_to_action.title}</div>
          }
        </div>
      )
    }
    // Button styling.
    let buttonClass = 'button_hard'
    if (options.card_corner_rounding && options.card_corner_rounding === '1') {
      buttonClass = 'button-soft'
    }
    else if (options.card_corner_rounding && options.card_corner_rounding === '2') {
      buttonClass = 'button-round'
    }
    // Render.
    return (
      <React.Fragment>
        {textBoxHtml.length &&
          <div className={`text-box-wrapper generic-text-box ${item.content_type}`}>
            {textListingLink}
            <div className={`text-box ${buttonClass} ${options.use_alt_textbox_colour && options.use_alt_textbox_colour === '1' ? 'alternate' : ''}`}>
              {textBoxHtml}
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default GenericTextBox;
