import React from 'react';

class StackedTextBox extends React.Component {
  render() {
    let item = {...this.props.item}
    let options = {...this.props.options}
    // variables set in the twig file. Not sure where they are coming from and if they are used.
    let textWrapperClass = 'text-wrapper'
    let itemClass = this.props.itemclassName || '';
    let alignClass = '';
    let authorAndPubDate = null
    let stackedTextBoxEventDate = ''
    let stackedTextBoxEventLocation = ''
    let stackedTextBoxEventMetadata = ''
    let stackedTextBoxPersonJobTitle = ''
    let stackedTextBoxPersonAffiliation = ''
    let stackedTextBoxPersonMetadata = []
    // Create link for overlay on text
    let textListingLink = item.link_url ?
      this.props.listingItemLink(item.link_url, item.title, !this.props.renderedLink, item.padlock) :
      "";
    // Text alignment.
    if (itemClass === 'landscape') {
      alignClass = options.landscape_text_alignment
      if (alignClass !== 'text-left') {
        textWrapperClass = 'text-wrapper-center'
      }
    }
    else if (itemClass === 'card') {
      alignClass = options.card_text_alignment
    }
    else if (itemClass === 'portrait') {
      alignClass = options.portrait_text_alignment
    }
    if (alignClass === 'title-center') {
      alignClass = 'text-center'
    }
    // Article metadata.
    if (item.content_type === "oxdrupal_article") {
      authorAndPubDate = []
      if (item.author && options.display_author) {
        authorAndPubDate = authorAndPubDate.concat([(item.author.trim())])
      }
      if (item.publication_date && !item.hide_publication_date) {
        authorAndPubDate = authorAndPubDate.concat([item.formatted_publication_date.oxdrupal_listing.trim()])
      }
    }
    // Event metadata.
    if (item.content_type === "oxdrupal_event") {
      // Date.
      if (item.event_date) {
        stackedTextBoxEventDate = (
          <div key="listing-item-event-date" className="text-row text-row-metadata listing-item-event-date">
            {
              options.metadata_labels &&
              options.metadata_labels.start_date &&
              <div className="metadata-label">
                {options.metadata_labels.start_date.trim()}
              </div>
            }
            <div className="metadata-data">
              {
                item.use_custom_event_date ?
                item.custom_event_date && item.custom_event_date.trim() :
                item.formatted_event_date.oxdrupal_listing.trim()
              }
            </div>
          </div>
        )
      }
      // Location.
      if (item.event_location) {
        stackedTextBoxEventLocation = (
          <div key="listing-item-event-location" className="text-row text-row-metadata listing-item-event-location">
            {
              options.metadata_labels &&
              options.metadata_labels.location &&
              <div className="metadata-label">
                {options.metadata_labels.location}
              </div>
            }
            <div className="metadata-data">
              {item.event_location}
            </div>
          </div>
        )
      }
      // Fiddling in order to output the two fields with a comma between and no space before the comma
      stackedTextBoxEventMetadata = []
      if (stackedTextBoxEventDate) {
        stackedTextBoxEventMetadata = stackedTextBoxEventMetadata.concat([stackedTextBoxEventDate])
      }
      if (stackedTextBoxEventLocation){
        stackedTextBoxEventMetadata = stackedTextBoxEventMetadata.concat([stackedTextBoxEventLocation])
      }
    }
    // Person metadata.
    if (item.content_type === "oxdrupal_person") {
      // Job title.
      if (item.person_job_title) {
        stackedTextBoxPersonJobTitle = (
          <div key="listing-item-person-job-title" className="text-row text-row-metadata listing-item-person-job-title">
            {
              options.metadata_labels &&
              options.metadata_labels.job_title &&
              <div className="metadata-label">
                {options.metadata_labels.job_title}
              </div>
            }
            <div className="metadata-data">
              {item.person_job_title}
            </div>
          </div>
        )
      }
      // Affiliation.
      if (item.person_affiliation) {
        stackedTextBoxPersonAffiliation = (
          <div key="listing-item-person-affiliation" className="text-row text-row-metadata listing-item-person-affiliation">
            {
              options.metadata_labels &&
              options.metadata_labels.affiliation &&
              <div className="metadata-label">
                {options.metadata_labels.affiliation}
              </div>
            }
            <div className="metadata-data">
              {item.person_affiliation}
            </div>
          </div>
        )
      }

      // Fiddling in order to output the two fields with a comma between and no space before the comma
      stackedTextBoxPersonMetadata = []
      if (stackedTextBoxPersonJobTitle) {
        stackedTextBoxPersonMetadata = stackedTextBoxPersonMetadata.concat([stackedTextBoxPersonJobTitle])
      }
      if (stackedTextBoxPersonAffiliation) {
        stackedTextBoxPersonMetadata = stackedTextBoxPersonMetadata.concat([stackedTextBoxPersonAffiliation])
      }
    }
    // Button alignment.
    let buttonAlign
    if (options.item_style === "tile_landscape_50_50" && item.call_to_action) {
     buttonAlign = 'text-center'
     if (options.landscape_text_alignment === 'text-left') {
       buttonAlign = 'text-right'
     }
    }
    // Set alignment for metadata.
    const findMetadataAlignclass = () => {
      if (itemClass === 'landscape') {
        alignClass = options.landscape_text_alignment
        if (alignClass !== 'text-left') {
          textWrapperClass = 'text-wrapper-center'
        }
      }
      else if (itemClass === 'card') {
        alignClass = options.card_text_alignment
      }
      else if (itemClass === 'portrait') {
        alignClass = options.portrait_text_alignment
      }
      if (alignClass === 'title-center') {
        alignClass = 'text-left'
      }
    }
    // Render.
    return (<React.Fragment>
      {
        item.content_type === "oxdrupal_event" &&
        options.item_style === 'tile_landscape_no_image' &&
        item.event_date &&
        <div className="event-flex-container">
          <div className="event-row">
            <div className="event-flex-item">{item.formatted_event_date.oxdrupal_teaser_date}</div>
          </div>
        </div>
      }
      <div className={`${textWrapperClass} stacked-text-box ${item.content_type}`}>
        {textListingLink}
        {
          item.category_subtype &&
          (options.display_content_subtype || options.display_style === 'banner') ?
            <div
              className={`text-row text-subtypes ${ alignClass }`}
              dangerouslySetInnerHTML={{ __html: item.category_subtype}}
            /> :
            (
              !item.category ||
              options.display_content_subtype
            ) &&
            options.item_style === 'tile_landscape_50_50' &&
            <div className={`text-row text-subtypes ${alignClass}`}>&nbsp;</div>
        }
        <div className="text-row text-row-title">
          {
            item.title &&
            <div className="listing-title">
              <h3 className={alignClass} dangerouslySetInnerHTML={{ __html: (item.padlock !== false ? item.padlock : '') + item.title}} />
            </div>
          }
        </div>
        {findMetadataAlignclass() && null}
        <div className={`text-row text-row-metadata ${alignClass}`}>
          {
            item.content_type === "oxdrupal_article" &&
            authorAndPubDate.length > 0 &&
            <div className="text-row text-row-metadata listing-item-pub-date">
              {
                options.metadata_labels &&
                options.metadata_labels.publication_date &&
                <div className="metadata-label">
                  {options.metadata_labels.publication_date}
                </div>
              }
              <div className="metadata-data" dangerouslySetInnerHTML={{ __html: authorAndPubDate.join(', ')}}/>
            </div>
          }
          {
            item.content_type === "oxdrupal_event" ?
              (
                options.item_style.indexOf("card") > -1 ?
                  stackedTextBoxEventMetadata :
                  (
                    stackedTextBoxEventMetadata.map( (item, index) =>
                    <React.Fragment key={index}>
                      { !!index && <span className="separator">, </span>}{item}
                    </React.Fragment>)
                  )
              ) :
              (
                item.event_location &&
                <div className="text-row text-row-metadata listing-item-event-location">
                  {options.metadata_labels && options.metadata_labels.location &&
                    <div className="metadata-label">
                      {options.metadata_labels.location}
                    </div>}
                  <div className="metadata-data">item.event_location</div>
                </div>
              )
          }
          {item.tertairy_text}
          {
            item.content_type === "oxdrupal_person" &&
            options.item_style.indexOf('card') > -1 ?
              stackedTextBoxPersonMetadata :
              (
                stackedTextBoxPersonMetadata.map( (item, index) =>
                <React.Fragment key={index}>
                  { !!index && <span className="separator">, </span>}{item}
                </React.Fragment>)
              )
          }
        </div>
        {
          options.display_listing_text &&
          item.teaser_text &&
          <div className={`text-row text-row-listing-text ${alignClass}`}>
            <div className="teaser-field listing-item-teaser-text teaser-text" dangerouslySetInnerHTML={{ __html: item.teaser_text}}/>
          </div>
        }
        {
          options.display_tertiary_text &&
          item.tertiary_text &&
          <div className={`text-row text-row-tertiary-text ${alignClass}`}>
            <div className="teaser-field listing-item-tertiary-text teaser-text" dangerouslySetInnerHTML={{ __html: item.tertiary_text}}/>
          </div>
        }
        {
          options.item_style === "tile_landscape_50_50" &&
          item.call_to_action &&
          <div className={`call-to-action-wrapper ${buttonAlign}`}>
            {
              item.cta_or_label === "call_to_action" ?
                <a
                  className="btn btn-primary btn-cta hvr-icon-grow"
                  href={item.call_to_action.url}
                  aria-label={item.call_to_action ? `Link to ${item.call_to_action.url }` : null}
                  target={ item.call_to_action.attributes.target ? item.call_to_action.attributes.target : ''}
                >
                  {item.call_to_action && item.call_to_action.title}
                </a> :
                <div className="label-cta">
                  {item.call_to_action && item.call_to_action.title}
                </div>
            }
          </div>
        }
      </div>
    </React.Fragment>)
  }
}

export default StackedTextBox;
