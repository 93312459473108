import React from 'react';
import GenericTextBox from './GenericTextBox';

class BreakerTextoverlayCentreRight extends React.Component {
  render() {
    let item = {...this.props.item}
    let options = {...this.props.options}
    let contentEl = []
    let linkHasBeenRendered = false
    // Create link for overlay on image
    let imageListingLink = item.link_url ?
      this.props.listingItemLink(item.link_url, item.title, true, item.padlock) :
      ""
    // Create link for overlay on video
    let videoListingLink = item.link_url ?
      this.props.listingItemLink(item.link_url, item.title, true, item.padlock) :
      ""
    // If video link exists, make image link un-focusable.
    if (item.link_url && item.video && item.video.youtube_id) {
      imageListingLink = this.props.listingItemLink(item.link_url, item.title, false, item.padlock)
    }
    // If a link has been set, pass this onto the Text Box.
    if (imageListingLink || videoListingLink) {
      linkHasBeenRendered = true
    }
    // Add image.
    contentEl.push(
      <div key='teaser-field' className="teaser-field image-wrapper">
        {imageListingLink}
        {item.teaser_image ?
          <div
            className={`teaser-field image ${item.using_placeholder_image ? 'no-image': ''}`}
            dangerouslySetInnerHTML={{ __html: item.rendered_image + '<div class="clearfix"></div>'}}
          /> :
          <div className="teaser-field image no-image"></div>
        }
      </div>
    )
    // Add video.
    if (item.video && item.video.youtube_id) {
      contentEl.push(
        <div key='teaser-image-video' className="teaser-field image-wrapper video-wrapper">
          {videoListingLink}
          <div className="video-overlay"></div>
          <div className="video-print-url">{`https://www.youtube-nocookie.com/embed/${item.video.youtube_id}`}</div>
          <iframe
            id={`youtube_player_${options.widget_id }_${ item.item_number }`}
            style={{border: 0}}
            allowfullscreen
            allow="autoplay; encrypted-media"
            title={"YouTube video player: " + item.title}
            width={item.video.width}
            height={item.video.height}
            src={`https://www.youtube-nocookie.com/embed/${item.video.youtube_id}?autoplay=1&controls=0&modestbranding=1&showinfo=0&rel=0&enablejsapi=1&origin=${item.video.origin}`}>
          </iframe>
        </div>
      )
    }
    // Add text box.
    contentEl.push(<GenericTextBox key='generic-text-box' item={item} options={options} listingItemLink={this.props.listingItemLink} renderedLink={linkHasBeenRendered}/>)

    // Render.
    return (
      <div className={`${item.classes.join(' ')}`}>
        <article
          {...item.article_attributes}
          aria-label={item.title ? item.title : ""}
          className={`${item.article_classes} ${(item.terms && item.terms.indexOf(options.promoted_term)) > -1 ? 'promoted' : ''}`}
          typeof="sioc:Item foaf:Document"
        >
          {contentEl}
        </article>
      </div>
    )
  }
}

export default BreakerTextoverlayCentreRight;
