import React from 'react';
import LandscapeTextoverlayCentreBottom from './ItemStyles/LandscapeTextoverlayCentreBottom';
import LandscapeTextoverlayCentreLeft from './ItemStyles/LandscapeTextoverlayCentreLeft';
import LandscapeTextoverlayCentreRight from './ItemStyles/LandscapeTextoverlayCentreRight';
import LandscapeTextoverlayLeftBottom from './ItemStyles/LandscapeTextoverlayLeftBottom';
import ImagetextLargeImageTextRight from './ItemStyles/ImagetextLargeImageTextRight';
import BreakerTextoverlayCentreMiddle from './ItemStyles/BreakerTextoverlayCentreMiddle';
import BreakerTextoverlayCentreBottom from './ItemStyles/BreakerTextoverlayCentreBottom';
import BreakerWithoutImage from './ItemStyles/BreakerWithoutImage';
import TileTextDisplayed from './ItemStyles/TileTextDisplayed';
import TileTextRollover from './ItemStyles/TileTextRollover';
import TileLandscapeSquareImage from './ItemStyles/TileLandscapeSquareImage';
import TileLandscapeNoImage from './ItemStyles/TileLandscapeNoImage';
import TileLandscape5050 from './ItemStyles/TileLandscape5050';
import CardIconImage from './ItemStyles/CardIconImage';
import CardNoImage from './ItemStyles/CardNoImage';
import CardBannerImage from './ItemStyles/CardBannerImage';
import RowWithSeparatorNoImage from './ItemStyles/RowWithSeparatorNoImage';
import RowWithSeparatorSquare from './ItemStyles/RowWithSeparatorSquare';
import RowWithSeparatorPortrait from './ItemStyles/RowWithSeparatorPortrait';
import RowWithoutSeparatorSquare from './ItemStyles/RowWithoutSeparatorSquare';
import RowWithoutSeparatorPortrait from './ItemStyles/RowWithoutSeparatorPortrait';
import RowWithoutSeparatorNoImage from './ItemStyles/RowWithoutSeparatorNoImage';

const Component = props  => {
    if (
        props.options['card_corner_rounding'] !== undefined &&
        props.options['card_corner_rounding'] > 0 &&
        props.options['item_style_grouping'] === "card"
    ) {
        props.item['article_classes'] += props.options['card_corner_rounding'] === '1' ? ' button-soft' : ' button-round'
    }

    if (props.item['is_promoted'] !== undefined && props.item['is_promoted'] === 1) {
      props.item.classes.push('promoted');
    }
    
    // Generic function for creating listing item links for each item style.
    const createListingItemLink = (linkHref, linkText, linkTabbable, padlock = false) => {
      let attrLinkTabbable = (linkTabbable === true) ?
        "" :
        -1;
      let padlockText = padlock !== false ?
        "Requires login: " :
        "";
      linkText = linkText ?
        <span className='screen-reader-only'>{padlockText + linkText}</span> :
        "";
      return (
        <a className='listing-item-link' href={linkHref} {...attrLinkTabbable} tabIndex={attrLinkTabbable}>
            {linkText}
        </a>
      )
    }

    let itemComponent = null
    switch (props.options.item_style) {
        case "landscape_textoverlay_centre_bottom":
            itemComponent = <LandscapeTextoverlayCentreBottom item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "landscape_textoverlay_centre_left":
            itemComponent = <LandscapeTextoverlayCentreLeft item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "landscape_textoverlay_centre_right":
            itemComponent = <LandscapeTextoverlayCentreRight item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "landscape_textoverlay_left_bottom":
            itemComponent = <LandscapeTextoverlayLeftBottom item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;

        case "imagetext_large_image_text_right":
            itemComponent = <ImagetextLargeImageTextRight item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;

        case "breaker_textoverlay_centre_middle":
            itemComponent = <BreakerTextoverlayCentreMiddle item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "breaker_textoverlay_centre_bottom":
            itemComponent = <BreakerTextoverlayCentreBottom item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "breaker_without_image":
            itemComponent = <BreakerWithoutImage item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;

        case "tile_text_displayed":
            itemComponent = <TileTextDisplayed item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "tile_text_rollover":
            itemComponent = <TileTextRollover item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "tile_landscape_square_image":
            itemComponent = <TileLandscapeSquareImage item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "tile_landscape_no_image":
            itemComponent = <TileLandscapeNoImage item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "tile_landscape_50_50":
            itemComponent = <TileLandscape5050 item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;

        case "card_icon_image":
            itemComponent = <CardIconImage item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "card_no_image":
            itemComponent = <CardNoImage item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "card_banner_image":
            itemComponent = <CardBannerImage item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;

        case "row_with_separator_no_image":
            itemComponent = <RowWithSeparatorNoImage item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "row_with_separator_square":
            itemComponent = <RowWithSeparatorSquare item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "row_with_separator_portrait":
            itemComponent = <RowWithSeparatorPortrait item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "row_without_separator_square":
            itemComponent = <RowWithoutSeparatorSquare item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "row_without_separator_portrait":
            itemComponent = <RowWithoutSeparatorPortrait item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;
        case "row_without_separator_no_image":
            itemComponent = <RowWithoutSeparatorNoImage item={props.item} options={props.options} listingItemLink={createListingItemLink}/>
            break;

        default:
            itemComponent = <div>Template {props.options.item_style} not found...!</div>
    }

    return itemComponent;
}

export default Component
