// import "flatpickr/dist/flatpickr.min.css";

import React, { useState, useEffect } from 'react';
import urlParams from '../utils/urlParams';
import formatDate from '../utils/formatDate';
import OxdrupalDuetDatePicker from './OxdrupalDuetDatePicker';

const DATE_FORMAT_UK = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/

let dateAdapter = {
  parse(value = "", createDate) {
    const matches = value.match(DATE_FORMAT_UK)
    if (matches) {
      return createDate(matches[3], matches[2], matches[1])
    }
  },
  format(date) {
    const month_options = { month: 'short'};
    return `${date.getDate()} ${new Intl.DateTimeFormat('en-GB', month_options).format(date)} ${date.getFullYear().toString().slice(-2)}`
  },
}

let localization = {
  buttonLabel: "Choose date",
  placeholder: "Select date",
  selectedDateMessage: "Selected date is",
  prevMonthLabel: "Previous month",
  nextMonthLabel: "Next month",
  monthSelectLabel: "Month",
  yearSelectLabel: "Year",
  closeLabel: "Close window",
  calendarHeading: "Choose a date",
  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  locale: "en-GB",
}

const Component = props  => {
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    if (props.taxonomyFilters) {
      fetch(`/api/oxdrupal_listings/taxonomy_terms?vids=${props.taxonomyFilters.map(f => f.vid).join(',')}`)
        .then( (response) => {
          return response.json();
        }).then((json) => {
        if (json.error) {
          console.warn("error: ", json.error);
        } else {
          setFilters(json.term_data)
        }
      }).catch( (err) => {
        console.warn("Error fetching widget filters", err);
      });
    }
  }, [props.taxonomyFilters])

  if (!filters && !props.showEventDateFilter && !props.displaySortOrder) {
    return null
  }

  let taxonomyNames = props.taxonomyFilters
    ? props.taxonomyFilters.reduce( (map, obj) => {
      map[obj.vid] = obj.label;
      return map;
    }, {})
    : []

  let sortOrderLabel = 'Order by page title'
  let sortOrderDescLabel = 'Descending'
  let sortOrderAscLabel = 'Ascending'
  let datePickerClear = ""
  if (props.showEventDateFilter) {
    datePickerClear = "date-picker-clear"
  }

  // Default values for event filters. Defaults to values fetched from URI
  let eventsFrom = props.selectedEventDateFrom;
  let eventsTo = props.selectedEventDateTo;

  if (props.options) {
    if (props.options['content_type'] === 'oxdrupal_article') {
      sortOrderLabel = 'Order by publication date'
      sortOrderDescLabel = 'Newest first'
      sortOrderAscLabel = 'Oldest first'
    }
    else if (props.options['content_type'] === 'oxdrupal_event') {
      sortOrderLabel = 'Order by event date'
    }
    else if (props.options['content_type'] === 'oxdrupal_person') {
      sortOrderLabel = 'Order by name'
    }
    // If no event selection could be gathered from URI, and the listing option event selection is set.
    if (
      !eventsFrom &&
      props.options.events_selection
    ) {
      // Conditionally set default values for date filters, based on event selection.
      switch (props.options.events_selection) {
        case "current":
          eventsFrom = formatDate()
          break;
        default:
          eventsFrom = '';
      }
    }

    // If a no To date is provided do not populate a default value.
    if (!eventsTo) {
      eventsTo = '';
    }
  }
  
  return (
    <div className="views-exposed-widgets clearfix view-filters views-exposed-form">
      <form id={`listing_filters_${props.widgetId}`}>
        {filters && props.taxonomyFilters && props.taxonomyFilters.map(t => (
          <div key={t.vid} className="views-exposed-widget views-widget-filter-tid">
            <label htmlFor={`tax-vocab-filter-${props.widgetId}-${t.vid}`}>{taxonomyNames[t.vid]}</label>
            <div className="views-widget">
              <div className="form-type-select form-item-tid form-item form-group">
                <select
                  id={`tax-vocab-filter-${props.widgetId}-${t.vid}`}
                  value={props.selectedFilters[t.vid] || 'all'}
                  aria-label={`filter the vocabulary ${taxonomyNames[t.vid]}`}
                  className="form-control form-select"
                  name={`${t.vid}_${taxonomyNames[t.vid].toLowerCase()}${props.widgetId}`}
                  onChange={ev => {
                    let params = urlParams(`filter-${t.vid}-${taxonomyNames[t.vid].toLowerCase()}`, ev.target.value, props.widgetId, props.urlParams)
                    params = urlParams('page', 0, props.widgetId, params)
                    props.updateHistory(params)
                  }}
                >
                  <option key='all' value="all"> -All- </option>
                  {filters[t.vid].map(opt => (
                    <option key={opt.tid} value={opt.tid}>{opt.label}</option>
                  )) }
                </select>
              </div>
            </div>
          </div>
        )) }
        {props.showEventDateFilter &&
        <React.Fragment>
          <div className="views-exposed-widget views-widget-filter-tid">
            <label htmlFor={`event_date_from_${props.widgetId}`}>Show events from</label>
            <div className="views-widget">
              <div className="form-type-select form-item-event-date-filter form-item form-group has-feedback">
                <OxdrupalDuetDatePicker
                  identifier={`event_date_from_${props.widgetId}`}
                  value={eventsFrom}
                  dateAdapter={dateAdapter}
                  localization={localization}
                  max={eventsTo}
                  first-day-of-week="0"
                  onChange={ev => {
                    let params = urlParams('event-date-from', formatDate(ev.detail.valueAsDate), props.widgetId, props.urlParams)
                    params = urlParams('page', 0, props.widgetId, params)
                    props.updateHistory(params)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="views-exposed-widget views-widget-filter-tid">
            <label htmlFor={`event_date_to_${props.widgetId}`}>Show events to</label>
            <div className="views-widget">
              <div className="form-type-select form-item-event-date-filter form-item form-group has-feedback">
              <OxdrupalDuetDatePicker
                  identifier={`event_date_to_${props.widgetId}`}
                  value={eventsTo}
                  min={eventsFrom}
                  dateAdapter={dateAdapter}
                  localization={localization}
                  first-day-of-week="0"
                  onChange={ev => {
                    let params = urlParams('event-date-to', formatDate(ev.detail.valueAsDate), props.widgetId, props.urlParams)
                    params = urlParams('page', 0, props.widgetId, params)
                    props.updateHistory(params)
                  }}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
        }
        {props.displaySortOrder &&
        <div className="views-exposed-widget views-widget-filter-tid">
          <label htmlFor={`edit-sort-order-${props.widgetId}`}>{sortOrderLabel}</label>
          <div className="views-widget">
            <div className="form-type-select form-item-sort-order form-item form-group">
              <select value={props.selectedSortOrder}
                      onChange={ev => {
                        let params = urlParams('sort-order', ev.target.value, props.widgetId, props.urlParams)
                        params = urlParams('page', 0, props.widgetId, params)
                        props.updateHistory(params)
                      }}
                      aria-label="Edit listing sort order" className="form-control form-select"
                      id={`edit-sort-order-${props.widgetId}`} name={`sort_order_${props.widgetId}`}>
                <option value="asc">{sortOrderAscLabel}</option>
                <option value="desc">{sortOrderDescLabel}</option>
              </select>
            </div>
          </div>
        </div>
        }
        <div className={`views-exposed-widget clear-filters views-submit-button ${datePickerClear}`}>
          <button className="btn btn-info form-submit" id={`clear-listing-${props.widgetId}`}
                  onClick={(ev) => {
                    ev.preventDefault()
                    let widgetParams = window.location.search.substring(1)
                      .split('&')
                      .filter(widgetParam => widgetParam.indexOf(`-${props.widgetId}=`) === -1)
                    let searchParams = '?' + widgetParams.join('&')
                    if (props.taxonomyPrefilters) {
                      // Check that the filters are an array. If not, convert the object keys to an array.
                      var filters = props.taxonomyPrefilters;
                      if (!Array.isArray(filters)) {
                        filters = Object.keys(filters)
                      }
                      filters.forEach(voc => {
                        if (filters[voc.vid]) {
                          searchParams = urlParams(`filter-${voc.vid}-${voc.label.toLowerCase()}`, filters[voc.vid][0], props.widgetId, searchParams)
                        }
                      })
                    }
                    props.updateHistory(searchParams)
                  }}
                  name={`clear-${props.widgetId}`} value="Clear" type="submit">Clear</button>
        </div>
      </form>
    </div>
  )
}

export default Component
