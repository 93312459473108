import React from 'react';
import urlParams from '../utils/urlParams';

export default props  => {
    const currentPage = parseInt(props.page);
    const totalPages = Math.ceil(props.totalResults/props.itemsPerPage);
    // Update query params for pagination links.
    const paginationClick = (value, e) => {
      // Prevent href navigation from triggering.
      e.preventDefault()
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      // Update query params.
      let params = urlParams('page', value, props.widgetId, props.urlParams)
      props.updateHistory(params)
    }
    let paginationComponents = null;
    if (totalPages !== 1) {
        paginationComponents = [];
        if (currentPage !== 0) {
          paginationComponents.push(<li key={"first"} className="pager-first" aria-label="First page">
            <a
              href={`${urlParams('page', 0, props.widgetId, props.urlParams)}`}
              aria-label="First page"
              onClick={e => {paginationClick(0,e)}}
            >
              first
            </a>
          </li>)
          paginationComponents.push(<li key={"prev"} className="prev">
            <a
              href={`${urlParams('page', currentPage-1, props.widgetId, props.urlParams)}`}
              aria-label="Previous page"
              onClick={e => {paginationClick(currentPage-1,e)}}
            >
              previous
            </a>
          </li>)
        }

        //  1
        if (currentPage > 2) {
          paginationComponents.push(<li key={"0"}>
            <a
              href={`${urlParams('page', 0, props.widgetId, props.urlParams)}`}
              aria-label="Result page 1"
              onClick={e => {paginationClick(0,e)}}
            >
              1
            </a>
          </li>)
        }
        //  ...
        if (currentPage > 3) {
          paginationComponents.push(<li key={"ellipsisPrev"} className='pager-ellipsis disabled'><span>…</span></li>)
        }

        //  5
        if (currentPage > 1) {
          paginationComponents.push(<li key={currentPage -2}>
            <a
              href={`${urlParams('page', currentPage-2, props.widgetId, props.urlParams)}`}
              aria-label={`Result page ${currentPage-2}`}
              onClick={e => {paginationClick(currentPage-1,e)}}
            >
              {currentPage -1}
            </a>
          </li>)
        }
        //  6
        if (currentPage > 0) {
          paginationComponents.push(<li key={currentPage - 1}>
            <a
              href={`${urlParams('page', currentPage-1, props.widgetId, props.urlParams)}`}
              aria-label={`Result page ${currentPage-1}`}
              onClick={e => {paginationClick(currentPage-1,e)}}
            >
              {currentPage}
            </a>
          </li>)
        }

        //  [7] (current page)
        paginationComponents.push(<li key={'cur'} className="active"><span>{currentPage+1}</span></li>)

        //  8
        if (currentPage < totalPages - 1) {
          paginationComponents.push(<li key={currentPage +1}>
            <a
              href={`${urlParams('page', currentPage+1, props.widgetId, props.urlParams)}`}
              aria-label={`Result page ${currentPage+1}`}
              onClick={e => {paginationClick(currentPage+1,e)}}
            >
              {currentPage + 2}
            </a>
          </li>)
        }
        //  9
        if (currentPage < totalPages - 2) {
          paginationComponents.push(<li key={currentPage +2}>
            <a
              href={`${urlParams('page', currentPage+2, props.widgetId, props.urlParams)}`}
              aria-label={`Result page ${currentPage+2}`}
              onClick={e => {paginationClick(currentPage+2,e)}}
            >
              {currentPage + 3}
            </a>
          </li>)
        }

        //  ...
        if (currentPage < totalPages - 4) {
          paginationComponents.push(<li key={"ellipsisNext"} className='pager-ellipsis disabled'><span>…</span></li>)
        }
        //  100
        if (currentPage < totalPages - 4) {
          paginationComponents.push(<li key={totalPages -1}>
            <a
              href={`${urlParams('page', totalPages-1, props.widgetId, props.urlParams)}`}
              aria-label={`Result page ${totalPages-1}`}
              onClick={e => {paginationClick(totalPages-1,e)}}
            >
              {totalPages}
            </a>
          </li>)
        }

        //  >   >>
        if (currentPage !== totalPages - 1) {
          paginationComponents.push(<li key={'next'} className="next">
            <a
              href={`${urlParams('page', currentPage+1, props.widgetId, props.urlParams)}`}
              aria-label="Next page"
              onClick={e => {paginationClick(currentPage+1,e)}}
            >
              next
              </a>
        </li>)
          paginationComponents.push(<li key={'last'} className="pager-last">
            <a
              href={`${urlParams('page', totalPages-1, props.widgetId, props.urlParams)}`}
              aria-label="Last page"
              onClick={e => {paginationClick(totalPages-1,e)}}
            >
              last
            </a>
        </li>)
        }
    }
    return (
      <div className="row">
        <div>
          <ul className="pagination text-center" data-widget-id={props.widgetId}>
            {paginationComponents}
          </ul>
        </div>
      </div>
    )
}
