import React from 'react';

export default props  => {
    let publicationOraEl = null
    if (
        Array.isArray(props.item.publication.oraUrl) && props.item.publication.oraUrl.length > 0 &&
        props.item.publication.oraUrl[0]['safe_value'] && props.item.publication.oraUrl[0]['safe_value'] !== ''
    ) {
        publicationOraEl = (
            <div className="publication-ora-record">
                <a target="_blank" title="View this publication in ORA"
                    rel="noopener noreferrer"
                    href={props.item.publication.oraUrl[0]['safe_value']}>ORA record</a>
            </div>
        )
    } else if (props.item.publication.inOra) {
        publicationOraEl = (
            <div className="publication-ora-record">
                <a target="_blank" title="View this publication in ORA"
                    rel="noopener noreferrer"
                    href={`${props.item.publication.oraUrlPrefix}${props.item.publication.idValue}`}>ORA record</a>
            </div>
        )
    }
    return <React.Fragment>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">×</button>
              <h4 className="modal-title" dangerouslySetInnerHTML={{ __html: props.item.publication.source.title}}/>
            </div>
            <div className="modal-body">
              <div className="list-group"
                  dangerouslySetInnerHTML={{__html: props.item.publication.source.fullView}}>
              </div>
            </div>
            <div className="modal-footer">
              <div className="publication-permalink">
                <a title="View this publication in its own page" href={`/${props.item.publication.source.pathAlias}`}>Permalink</a>
              </div>
              {props.item.publication.doi &&
                  <div dangerouslySetInnerHTML={{__html: props.item.publication.doiValue}}/>
              }
              {publicationOraEl}
            </div>
          </div>
        </div>
    </React.Fragment>
}
