import React from 'react';

export default props  => {
    let item = {...props.item}
    
    const listItemEl = (
          <li className="search-result oxdrupal-section-search-result">
            <h3 className="search-title"><a href={item.link}>
                {item.title} {item.file &&
                    <span dangerouslySetInnerHTML={{__html: item.file}}/>}
            </a></h3>
            {item.snippet &&
                <div className="search-snippet" dangerouslySetInnerHTML={{__html: item.snippet}}>
                </div>
            }
            <div className="search-url"><a href={item.link}>{item.link}</a></div>
            {item.parent_entity_link &&
                <div className="file-parent-link"><em>Found on page: </em>
                    {item.parent_entity_link.map( (parent, idx) =>
                        <span key={idx} dangerouslySetInnerHTML=
                            {{__html: parent + (idx !== item.parent_entity_link.length-1 ? ', ' : '') }} />
                    )}
                </div>
            }
          </li>
    )
    
    return listItemEl
}
