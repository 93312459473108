import React from 'react';
import StackedTextBox from './StackedTextBox';

class TileLandscapeSquareImage extends React.Component {
  render() {
    let item = {...this.props.item}
    let options = {...this.props.options}
    let contentEl = []
    let itemClass = 'landscape'
    let linkHasBeenRendered = true
    // Create link for overlay on image
    let imageListingLink = item.link_url ?
      this.props.listingItemLink(item.link_url, item.title, false, item.padlock) :
      ""
    // Create link for overlay on video
    let videoListingLink = item.link_url ?
      this.props.listingItemLink(item.link_url, item.title, false, item.padlock) :
      ""
    // If video link exists, make image link un-focusable.
    if (item.link_url && item.video && item.video.youtube_id) {
      imageListingLink = this.props.listingItemLink(item.link_url, item.title, false, item.padlock)
    }
    // If a link has been set, pass this onto the Text Box.
    if (imageListingLink || videoListingLink) {
      linkHasBeenRendered = false
    }
    // Add image.
    if (item.teaser_image) {
      contentEl.push(
        <div key='teaser-field' className="teaser-field image-wrapper">
          {imageListingLink}
          <div
            className='teaser-field image'
            dangerouslySetInnerHTML={{ __html: item.rendered_image + '<div class="clearfix"></div>'}}
          />
        </div>
      )
    }
    // Add video,
    if (item.video && item.video.youtube_id) {
      contentEl.push(
        <div key='teaser-image-video' className="teaser-field image-wrapper video-wrapper">
          {videoListingLink}
          <div className="video-overlay"></div>
          <div className="video-print-url">{`https://www.youtube-nocookie.com/embed/${item.video.youtube_id}`}</div>
          <iframe
            id={`youtube_player_${options.widget_id }_${ item.item_number }`}
            style={{border: 0}}
            allowfullscreen
            allow="autoplay; encrypted-media"
            title={"YouTube video player: " + item.title}
            width={item.video.width}
            height={item.video.height}
            src={`https://www.youtube-nocookie.com/embed/${item.video.youtube_id}?controls=0&modestbranding=1&showinfo=0&rel=0&enablejsapi=1&origin=${item.video.origin}`}>
          </iframe>
        </div>
      )
    }
    // Add text box.
    contentEl.push(<StackedTextBox key='stacked-text-box' item={item} options={options} itemclassName={itemClass} listingItemLink={this.props.listingItemLink} renderedLink={linkHasBeenRendered}/>)

    // Render.
    return (
      <div className={`${item.classes.join(' ')}`}>
        <article
          {...item.article_attributes}
          aria-label={item.title ? item.title : ""}
          className={`${item.article_classes} ${(item.terms && item.terms.indexOf(options.promoted_term)) > -1 ? 'promoted' : ''}`}
          typeof="sioc:Item foaf:Document"
        >
          <div className="text-box-wrapper">
            <div className="text-box-and-chevron-wrapper no-info-box">
              <div className="text-box">
                {contentEl}
              </div>
            </div>
          </div>
        </article>
        <div className="listing-spacer listing-item-spacer"></div>
      </div>
    )
  }
}

export default TileLandscapeSquareImage;
