
const urlParams = (param, value, widgetId, params) => {
    //  this.props.urlParams starts with '?'
    let widgetFound = false
    let widgetParams = (params.startsWith('?') ? params.slice(1) : params)
    .split('&')
    .map(widgetParam => {
        if (widgetParam.startsWith(`${param}-${widgetId}`)) {
            widgetFound = true
            if (value === 'all') {
                return null
            } else {
                return `${param}-${widgetId}=${value}`
            }
        } else {
            return widgetParam
        }
    })
    .filter(widgetParam => !!widgetParam)
    if (!widgetFound) {
        widgetParams.push(`${param}-${widgetId}=${value}`)
    }
    
    return '?' + widgetParams.join('&')
}

export default urlParams
