import React, { useState, useEffect, useRef } from 'react';
import SearchResult from './SearchResult';
import Pagination from './Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import urlParams from '../utils/urlParams';

const ITEMS_PER_PAGE = 10
export default props  => {
  const [state, setState] = useState({fetchFinished: false, options: {}});
  const firstRender = useRef(true);
  let params = []
  Object.keys(props.listParams).forEach( key => {
    if (Array.isArray(props.listParams[key])) {
      if (props.listParams[key].length > 0) {
        params.push(props.listParams[key].map( v => `${key}[]=${v.label}`).join('&'))
      }
    }
    else {
      if (props.listParams[key] !== undefined && props.listParams[key] !== null && props.listParams[key] !== '') {
        params.push(`${key}=${props.listParams[key]}`)
      }
    }
  })

  params.push(`search_scope=${props.searchScopeId}`)
  const paramsStr = params.join('&')
  useEffect((ef) => {
    setState(state => state.fetchFinished ? {fetchFinished: false, options: state.options} : state)
    if (props.listParams.searchTerm !== undefined && props.listParams.searchTerm !== '') {
      fetch(`/oxdrupal_search/section/${props.listParams.searchTerm}?${paramsStr}`)
        .then( (response) => {
          return response.json();
        }).then((json) => {
          if (json.error) {
            console.warn("error: ", json.error);
          }
          else {
            setState({
              options: {totalItems: json.total, itemsPerPage: ITEMS_PER_PAGE},
              items: json.results,
              fetchFinished: true
            })
          }
        }).catch( (err) => {
          console.warn("Error fetching widget data", err);
        });
    }
    else {
      setState({
        options: {},
        items: [],
        fetchFinished: true
      });
      console.log("no search text provided yet");
    }
  }, [    // only run useEffect if one of these changes
      paramsStr, props.widgetId, props.nodeId, props.listParams.searchTerm
  ])

  useEffect((ef) => {
    if (!firstRender.current) {
      setTimeout(() => {
        let spinnerEl = document.getElementById(`list-spinner-${props.widgetId}`)
        spinnerEl.scrollIntoView({behavior: "smooth", block: "end"})
      })
    }
    firstRender.current = false
  }, [props.widgetId, props.listParams.page])

  const listEl = (
    <ul className="search-results apachesolr_search-results" style={{paddingLeft:0, listStyle: "none"}}>
      {state.items && state.items.length > 0 ?
        state.items.map( (item, idx) => (
          <SearchResult
            key={idx}
            item={item}
            loopCounter={idx + 1}
            widgetId={props.widgetId}
            options={state.options}
          />
        )) :
        <li><strong>No results found</strong></li>
      }
    </ul>
  )

  return <div className="row page-search">
    <div>
      <div className="col-md-12 section search-freetext-filter-wrapper">
        <label htmlFor={"search_" + props.widgetId} className="sr-only">Search term</label>
        <input id={"search_" + props.widgetId} type="text" className="search form-control form-input form-filter" style={{display: 'inline-block',marginRight: '4px', width: "auto"}} placeholder="Search this section..."
          defaultValue={props.listParams.searchTerm}
          disabled={!state.fetchFinished}
          onKeyDown={ev => {
            if (ev.keyCode === 13) {
              let textValue = document.getElementById(`search_${props.widgetId}`).value;
              if (textValue.length > 2) {
                setTimeout(() => {
                  let params = urlParams('searchtext', textValue, props.widgetId, props.urlParams)
                  params = urlParams('page', 0, props.widgetId, params)
                  props.updateHistory(params);
                }, 1000)
              }
            }
          }}
        />
        <input
          type="button"
          value="Search"
          onClick={ ev => {
            let textValue = document.getElementById(`search_${props.widgetId}`).value;
            setTimeout(() => {
              let params = urlParams('searchtext', textValue, props.widgetId, props.urlParams)
              params = urlParams('page', 0, props.widgetId, params)
              props.updateHistory(params);
            }, 1000)
          }}
        />
      </div>
    </div>
    {!firstRender.current &&
      <div aria-live="polite">
        {state.fetchFinished ? <div className="screen-reader-only">The list was updated</div> : null}
      </div>
    }
    {
      state.fetchFinished ?
        <React.Fragment>
          {
            state.items != null && state.items.length ?
              listEl :
              props.listParams.searchTerm === "" || props.listParams.searchTerm === undefined ?
                <span/> :
                <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12"><strong>No results found</strong></div>
          }
          {
            state.options && state.options.totalItems > state.options.itemsPerPage &&
              <Pagination
                widgetId={props.widgetId}
                itemsPerPage={state.options.itemsPerPage}
                urlParams={props.urlParams}
                totalResults={state.options.totalItems || 0}
                page={props.listParams.page || 0}
                updateHistory={props.updateHistory}
              />
          }
      </React.Fragment> :
        <div className='loading' style={{textAlign: 'center', padding: '20px'}}>
            <FontAwesomeIcon
              id={`list-spinner-${props.widgetId}`}
              icon={faSpinner}
              className="fa-pulse fa-2x fa-fw"
              alt="Loading listings"
            />
            <div>Searching...</div>
          </div>
    }
  </div>
}
