// Support Internet Explorer
import 'react-app-polyfill/ie9';

import React from 'react';
import { createRoot } from 'react-dom/client';
// import './index.css';
import App from './components/App';
// import * as serviceWorker from './serviceWorker';

document.addEventListener("DOMContentLoaded", function() {
   var listingEls = document.getElementsByClassName("react-section-search");
    for (let i = 0; i < listingEls.length; i++) {
        // parse the dataset of the dom Element
        let dataset = {...listingEls[i].dataset}

        Object.keys(dataset).forEach(d => {
            if (dataset[d] === '') {
                delete dataset[d]
            } else if (dataset[d] === 'true') {
                dataset[d] = true
            } else if (dataset[d] === 'false') {
                dataset[d] = false
            } else if (!isNaN(dataset[d])) {
                dataset[d] = Number(dataset[d])
            }
        })

        const root = createRoot(listingEls[i]);
        root.render(<App {...dataset}/>);
    }
})
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
