import React from 'react';
import StackedTextBox from './StackedTextBox';

class CardIconImage extends React.Component {
  render() {
    let item = {...this.props.item}
    let options = {...this.props.options}
    let contentEl = []
    let itemClass = 'card'
    let linkHasBeenRendered = false
    // Create link for overlay on image
    let imageListingLink = item.link_url ?
      this.props.listingItemLink(item.link_url, item.title, true, item.padlock) :
      ""
    // If a link has been set, pass this onto the Text Box.
    if (imageListingLink) {
      linkHasBeenRendered = true
    }
    // Add image.
    contentEl.push(
      <div key='teaser-field' className="teaser-field image-wrapper">
        {imageListingLink}
        {
          item.teaser_image &&
          <div
            className={`teaser-field image ${item.using_placeholder_image ? 'no-image': ''}`}
            dangerouslySetInnerHTML={{ __html: item.rendered_image + '<div class="clearfix"></div>'}}
          />
        }
      </div>
    )
    // Add text box.
    contentEl.push(<StackedTextBox key='stacked-text-box' item={item} options={options} itemclassName={itemClass} listingItemLink={this.props.listingItemLink} renderedLink={linkHasBeenRendered}/>)
    // Add call to action.
    if (item.call_to_action) {
      let alignClass = ''
      if (options.card_text_alignment === 'text-left' && item.article_classes.indexOf('borderoff')) {
        alignClass = 'text-right'
      }
      else {
        alignClass = 'text-center'
      }
      contentEl.push(
        <div key='call-to-action-wrapper' className={`call-to-action-wrapper ${alignClass}`}>
          {item.cta_or_label === "call_to_action" ?
            <a
              className="btn btn-primary btn-cta hvr-icon-grow"
              href={item.call_to_action.url}
              aria-label={item.call_to_action ? `Link to ${item.call_to_action.url }` : null}
              target={ item.call_to_action.attributes.target ? item.call_to_action.attributes.target : null}
            >
              {item.call_to_action && item.call_to_action.title}
            </a> :
            <div className="label-cta">
              {item.call_to_action && item.call_to_action.title}
            </div>
          }
        </div>
      )
    }
    
    // Render.
    return (
      <div className={`${item.classes.join(' ')}`}>
        <article
          {...item.article_attributes}
          aria-label={item.title ? item.title : ""}
          className={`${item.article_classes} ${(item.terms && item.terms.indexOf(options.promoted_term)) > -1 ? 'promoted' : ''}`}
          typeof="sioc:Item foaf:Document"
        >
          {contentEl}
        </article>
        <div className="listing-spacer listing-item-spacer"></div>
      </div>
    )
  }
}

export default CardIconImage;
