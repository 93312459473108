import React, { useState, useEffect } from 'react';
import Listing from './Listing';

export default (props)  => {
    const newQ = new URLSearchParams(window.location.search)
    const [query, setQuery] = useState(window.location.search)
    // Updates the query state.
    const updateQuery = (query) => {
        setQuery(query)
    }
    // Gets parameters from URL.
    const getUrlParams  = (query, param) => {
      // Escape these characters ?.,&#^ to allow the regex match run correctly
      let p = param.replace(/[?.,&#^]+/g,'\\$&')
      // Find the occurances of the provided parameter in the query string.
      var results = [...query.matchAll(new RegExp('[?&]' + p + '(\\[\\])?=([^&#]*)', 'g'))];
      // If no matches are found, exit.
      if (!results.length) {
        return null;
      }
      else {
        // Check for whether this is an array value (e.g. filter-types-xxxxx[] vs. filter-types-xxxx)
        if (results[0][1] !== undefined) {
          // Split the results on ",". ("," in the actual labels will have been encoded at this point).
          var ret = null
          var splitRes = results[0][2].split(',')
          // Loop through split items.
          splitRes.forEach((res) => {
            // Decode the result to fetch the proper label.
            var decoded = decodeURI(decodeURIComponent(res))
            if (decoded) {
              // If we don't already have values, create empty array.
              if (ret === null) {
                ret = []
              }
              // Push a value to the return.
              ret.push({
                'value': decoded,
                'label': decoded,
              })
            }
          })
          return ret;
        }
        // If this isn't an array value, simply return the found value.
        else {
          return results[0][2]
        }
      }
    }
    // Update browser session history with adjusted parameters.
    const updateHistory = (params) => {
        // Build base URL.
        let pageURL = window.location.protocol + "//" + window.location.host + window.location.pathname
        const url = new URL(pageURL);
        // Split param string into individual parameters.
        let split = params.split(/(\?|&)/g)
        let queryString = '?'
        split.forEach(value => {
            // Skip blank/connecting items.
            if (!["", "?", "&"].includes(value)) {
                if (queryString !== "?") {
                  queryString += "&"
                }
                let arrayNote = ""
                // Split into key = value pair.
                let reSplit = value.split("=")
                // If filter param is NOT page, or doesn't already have an array signifier, add one.
                if (
                  !reSplit[0].startsWith("page") &&
                  !reSplit[0].startsWith("freetext_filter") &&
                  !reSplit[0].startsWith("widget_limit_to_favourites") &&
                  !reSplit[0].endsWith("[]")
                  ) {
                  arrayNote = "[]"
                }
                queryString += reSplit[0]+arrayNote+"="+reSplit[1]
            }
        })
        url.search = queryString
        // Update history.
        window.history.pushState({}, '', url);
        // Update query, trigger re-render.
        updateQuery(url.search)
    }
    // Parameters.
    // - URL params.
    let params = window.location.search
    let widgetMaxPublicationsToDisplay = newQ.get('widget_max_publications_to_display-' + props.widgetId) || 25
    let widgetShowAuthorAndEditorNames = newQ.get('widget_show_author_and_editor_names-' + props.widgetId) || null
    let widgetLimitToFavourites = newQ.get('widget_limit_to_favourites-' + props.widgetId) || '0'
    let widgetPageTitle = newQ.get('widget_page_title-' + props.widgetId) || null
    let maxPublicationsToDisplay = props.isBriefList === '1'
      ? props.maxPublicationsToDisplay
      : newQ.get('max_publications_to_display-' + props.widgetId) || 25
    let freetextFilter = newQ.get('freetext_filter-' + props.widgetId) || null
    let filterKeywords = getUrlParams(window.location.search, 'filter_keywords-' + props.widgetId) || []
    let filterAuthors = getUrlParams(window.location.search, 'filter_authors-' + props.widgetId) || []
    let filterYears = getUrlParams(window.location.search, 'filter_years-' + props.widgetId) || []
    let filterTypes = getUrlParams(window.location.search, 'filter_types-' + props.widgetId) || []
    let filterSeries = getUrlParams(window.location.search, 'filter_series-' + props.widgetId) || []

    let page = getUrlParams(window.location.search, 'page-' + props.widgetId) ||  0
    
    // add params to the url on initial load
    useEffect( () => {
      if (props.searchParams && props.searchParams.length) {
        updateHistory(decodeURIComponent(props.searchParams));
      }
    }, [props.searchParams])
    
    // Render.
    return (
        <Listing
            {...props}
            query={query}
            listParams={{
                widget_max_publications_to_display: widgetMaxPublicationsToDisplay,
                widget_show_author_and_editor_names: widgetShowAuthorAndEditorNames,
                widget_limit_to_favourites: widgetLimitToFavourites,
                widget_page_title: widgetPageTitle,
                max_publications_to_display: maxPublicationsToDisplay,
                freetext_filter: freetextFilter,
                filter_keywords: filterKeywords,
                filter_authors: filterAuthors,
                filter_years: filterYears,
                filter_types: filterTypes,
                filter_series: filterSeries,
                page: page,
            }}
            urlParams={decodeURI(params)}
            updateQuery={updateQuery}
            updateHistory={updateHistory}
        />
    )
}
