// Support Internet Explorer
import 'react-app-polyfill/ie9';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import urlParams from './utils/urlParams';
// Import Duet Date Picker
import { defineCustomElements } from "@duetds/date-picker/dist/loader";

// Register Duet Date Picker
defineCustomElements(window);

document.addEventListener("DOMContentLoaded", function() {
    var listingEls = document.getElementsByClassName("react-listing");
    for (let i = 0; i < listingEls.length; i++) {
        // parse the dataset of the dom Element
        let dataset = {...listingEls[i].dataset}

        Object.keys(dataset).forEach(d => {
            if (dataset[d] === '') {
                delete dataset[d]
            } else if (dataset[d] === 'true') {
                dataset[d] = true
            } else if (dataset[d] === 'false') {
                dataset[d] = false
            } else if (!isNaN(dataset[d])) {
                dataset[d] = Number(dataset[d])
            }
        })
        if (dataset.taxonomyFilters && dataset.taxonomyFilters !== '[]') {
            dataset.taxonomyFilters = JSON.parse(dataset.taxonomyFilters)
        } else {
            delete dataset.taxonomyFilters
        }
        if (dataset.taxonomyPrefilters) {
            dataset.taxonomyPrefilters = JSON.parse(dataset.taxonomyPrefilters)

            // add prefiltered values to the url if:
            // nothing else can be found in the location.search related to this widget AND
            // we are coming from a different page
            let location = window.location.origin + window.location.pathname
            let referrer = document.referrer ? document.referrer.split('?')[0] : ''
            if (window.location.search.indexOf(`-${dataset.widgetId}=`) === -1 && location !== referrer && dataset.taxonomyFilters) {
                let searchParams = ""

                dataset.taxonomyFilters.forEach(voc => {
                    if (dataset.taxonomyPrefilters[voc.vid]) {
                        searchParams = urlParams(`filter-${voc.vid}-${voc.label.toLowerCase()}`, dataset.taxonomyPrefilters[voc.vid][0], dataset.widgetId, searchParams)
                    }
                })
                window.history.pushState({}, '', createSearch(window.location.search, searchParams));
            }
        }

        const root = createRoot(listingEls[i]);
        root.render(<App {...dataset}/>);
    }
})

const createSearch = (currentSearch, searchParams) => {
    // Remove leading slash if present
    let newSearch = currentSearch.indexOf('/') === 0 ? currentSearch.slice(1) : currentSearch
    if (searchParams.length) {
        // Ensure there is a ?
        newSearch = newSearch.indexOf('?') < 0 ? '?' + newSearch : newSearch
        if (newSearch.length > 1) {
            // If newSearch is not just '?', append ampersand
            newSearch = newSearch + '&'
        }
    }
    newSearch = newSearch + (searchParams.startsWith('?') ? searchParams.slice(1) : searchParams)
    return newSearch
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
