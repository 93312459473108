import React from 'react';
import StackedTextBox from './StackedTextBox';

class RowWithSeparatorSquare extends React.Component {
  render() {
    let item = {...this.props.item}
    let options = {...this.props.options}
    let contentEl = []
    let textBoxEl = []
    let linkHasBeenRendered = false
    // Create link for overlay on image
    let imageListingLink = item.link_url ?
      this.props.listingItemLink(item.link_url, item.title, true, item.padlock) :
      ""
    // Create link for overlay on video
    let videoListingLink = item.link_url ?
      this.props.listingItemLink(item.link_url, item.title, true, item.padlock) :
      ""
    // If video link exists, make image link un-focusable.
    if (item.link_url && item.video && item.video.youtube_id) {
      imageListingLink = this.props.listingItemLink(item.link_url, item.title, false, item.padlock)
    }
    // If a link has been set, pass this onto the Text Box.
    if (imageListingLink || videoListingLink) {
      linkHasBeenRendered = true
    }
    // Add info box.
    if (item.content_type === "oxdrupal_event") {
      contentEl.push(
        <div key= 'listing-item-info-box-wrapper' className="listing-item-info-box-wrapper">
          <div className="listing-item-info-box">
            {(item.event_date && item.formatted_event_date && item.formatted_event_date.oxdrupal_teaser_date) || ''}
            <div className="clearfix"></div>
          </div>
        </div>
      )
    }
    // Add image.
    if (item.teaser_image) {
      textBoxEl.push(
        <div key='teaser-field-1' className="teaser-field image-wrapper">
          {imageListingLink}
          <div
            className="teaser-field image"
            dangerouslySetInnerHTML={{ __html: item.rendered_image + '<div className="clearfix"></div>'}}
          />
        </div>
      )
    }
    // Add image source(?).
    if (item.teaser_image_src) {
      textBoxEl.push(
        <div key='teaser-field-2' className="teaser-field image-wrapper">
          <div className="teaser-field image">
            <img
              alt="placeholder"
              typeof="foaf:Image"
              className="img-responsive"
              src={item.teaser_image_src}
              width={item.teaser_image_width ? item.teaser_image_width : null}
              height={item.teaser_image_height ? item.teaser_image_height : null}
            />
            <div className="clearfix"></div>
          </div>
        </div>
      )
    }
    // Add video.
    if (item.video && item.video.youtube_id) {
      textBoxEl.push(
        <div key='teaser-image-video' className="teaser-field image-wrapper video-wrapper">
          {videoListingLink}
          <div className="video-overlay"></div>
          <div className="video-print-url">{`https://www.youtube-nocookie.com/embed/${item.video.youtube_id}`}</div>
          <iframe
            id={`youtube_player_${options.widget_id }_${ item.item_number }`}
            style={{border: 0}}
            allowfullscreen
            allow="autoplay; encrypted-media"
            title={"YouTube video player: " + item.title}
            width={item.video.width}
            height={item.video.height}
            src={`https://www.youtube-nocookie.com/embed/${item.video.youtube_id}?autoplay=1&controls=0&modestbranding=1&showinfo=0&rel=0&enablejsapi=1&origin=${item.video.origin}`}>
          </iframe>
        </div>
      )
    }
    // Add text box.
    textBoxEl.push(<StackedTextBox key='stacked-text-box' item={item} options={options} listingItemLink={this.props.listingItemLink} renderedLink={linkHasBeenRendered}/>)
    // Add wrapper.
    contentEl.push(
      <div key='text-box' className={`text-box-and-chevron-wrapper ${!item.event_date ? 'no-info-box' : ''}`}>
        <div className="text-box">
          {textBoxEl}
        </div>
        <div className="list-style-arrow-wrapper">
          {
            item.link_url &&
            <div className="list-style-arrow fas fa-2x fa-chevron-circle-right"></div>
          }
        </div>
      </div>
    )

    // Render.
    return (
      <div className={`${item.classes.join(' ')}`}>
        <article
          className={item.article_classes}
          aria-label={item.title ? item.title : ""}
          about={item.guid}
          typeof="sioc:Item foaf:Document"
        >
          <div className="text-box-wrapper">
            {contentEl}
          </div>
        </article>
        <div className="listing-spacer listing-item-spacer"></div>
      </div>
    )
  }
}

export default RowWithSeparatorSquare;
