// Support Internet Explorer
import 'react-app-polyfill/ie9';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import urlParams from './utils/urlParams';
// import * as serviceWorker from './serviceWorker';
document.addEventListener("DOMContentLoaded", function() {
    var symplecticEls = document.getElementsByClassName("react-symplectic-publications");
    for (let i = 0; i < symplecticEls.length; i++) {
        const symplecticEl = symplecticEls[i]
        let dataset = {...symplecticEl.dataset}
        
        let searchParams = ""
        // if no filters are found in the url: that's not a "saved bookmark with filters"
        // we have to add the prefilters
        if (
            decodeURIComponent(window.location.search).indexOf(`widget_limit_to_favourites-${dataset.widgetId}=`) === -1 &&
            decodeURIComponent(window.location.search).indexOf(`filter_keywords-${dataset.widgetId}[]=`) === -1 &&
            decodeURIComponent(window.location.search).indexOf(`filter_authors-${dataset.widgetId}[]=`) === -1 &&
            decodeURIComponent(window.location.search).indexOf(`filter_years-${dataset.widgetId}[]=`) === -1 &&
            decodeURIComponent(window.location.search).indexOf(`filter_types-${dataset.widgetId}[]=`) === -1 &&
            decodeURIComponent(window.location.search).indexOf(`filter_series-${dataset.widgetId}[]=`) === -1
        ) {
            
            // add prefilters, but only if we are on the full filtering view
            if (dataset.favouritesOnly === "1" && dataset.isBriefList === "0") {
                searchParams = urlParams(`widget_limit_to_favourites`, "1", dataset.widgetId, searchParams)
            }
            if (dataset.publicationType  && dataset.isBriefList === "0") { //and not another publicationType in searchParams
                searchParams = urlParams(`filter_types`, JSON.parse(dataset.publicationType).filter(t => !!t), dataset.widgetId, searchParams)
            }
            if (dataset.symplecticSeries  && dataset.isBriefList === "0") { //and not another publicationSeries in searchParams
                searchParams = urlParams(`filter_series`, JSON.parse(dataset.symplecticSeries).filter(t => !!t), dataset.widgetId, searchParams)
            }
        }
        const root = createRoot(symplecticEl);
        root.render(<App {...dataset} searchParams={searchParams}/>);
    }
})
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
