import React from 'react';

class LandscapeTextoverlayLeftBottom extends React.Component {
    render() {
      let item = {...this.props.item}
      let options = {...this.props.options}
      let contentEl = []
      let alignClass = ''
      let linkHasBeenRendered = false
      // Create link for overlay on image
      let imageListingLink = item.link_url ?
        this.props.listingItemLink(item.link_url, item.title, true, item.padlock) :
        ""
      // Create link for overlay on video
      let videoListingLink = item.link_url ?
        this.props.listingItemLink(item.link_url, item.title, true, item.padlock) :
        ""
      // If video link exists, make image link un-focusable.
      if (item.link_url && item.video && item.video.youtube_id) {
        imageListingLink = this.props.listingItemLink(item.link_url, item.title, false, item.padlock)
      }
      // If a link has been set, pass this onto the Text Box.
      if (imageListingLink || videoListingLink) {
        linkHasBeenRendered = true
      }
      // Create link for overlay on text
      let textListingLink = item.link_url ?
        this.props.listingItemLink(item.link_url, item.title, !linkHasBeenRendered, item.padlock) :
        "";
      // Add image.
      contentEl.push(
        <div key='teaser-field' className="teaser-field image-wrapper">
          {imageListingLink}
          {item.teaser_image ?
            <div
              className='teaser-field image-wrapper'
              dangerouslySetInnerHTML={{ __html: item.rendered_image + '<div class="clearfix"></div>'}}
            /> :
            <div className="teaser-field image no-image">
              <img
                alt="placeholder"
                typeof="foaf:Image"
                className="img-responsive"
                src="/sites/all/themes/custom/oxtheme/images/transparent_6_by_4.png"
                width={options.teaser_image_width}
                height={ options.teaser_image_height}
              />
            </div>
          }
        </div>
      )
      // Add video.
      if (item.video && item.video.youtube_id) {
        contentEl.push(
          <div key='teaser-image-video' className="teaser-field image-wrapper video-wrapper">
            {videoListingLink}
            <div className="video-overlay"></div>
            <div className="video-print-url">{`https://www.youtube-nocookie.com/embed/${item.video.youtube_id}`}</div>
            <iframe
              id={`youtube_player_${options.widget_id }_${ item.item_number }`}
              style={{border: 0}}
              allowfullscreen
              allow="autoplay; encrypted-media"
              title={"YouTube video player: " + item.title}
              width={item.video.width}
              height={item.video.height}
              src={`https://www.youtube-nocookie.com/embed/${item.video.youtube_id}?autoplay=1&controls=0&modestbranding=1&showinfo=0&rel=0&enablejsapi=1&origin=${item.video.origin}`}>
            </iframe>
          </div>
        )
      }
      // Build text box HTML.
      let textBoxHtml = []
      // Add title.
      if (item.title) {
        textBoxHtml.push(
          <div key='listing-title' className="listing-title">
            <h3 dangerouslySetInnerHTML={{ __html: (item.padlock !== false ? item.padlock : '') + item.title}} />
          </div>
        )
      }
      if (item.content_type !== "oxdrupal_person") {
        // If image gallery.
        if (options.display_style === "image_gallery") {
          if (item.teaser_image_caption) {
            textBoxHtml.push(
              <div key='teaser-field-2' className="teaser-field text-wrapper">
                <div className="teaser-field teaser-text image-caption">
                  {item.teaser_image_caption}
                </div>
              </div>
            )
          }
        }
        else {
          // Add teaser/tertiary text.
          if (item.teaser_text) {
            if (options.item_style_grouping !== 'tile') {
              alignClass = ''
            }
            textBoxHtml.push(
              <div key='teaser-field-2' className="teaser-field text-wrapper">
                <div className="teaser-field listing-item-teaser-text teaser-text">
                  {
                    item.short_teaser_text ?
                      <React.Fragment>
                        <div
                          className={`teaser-field listing-item-teaser-text teaser-text shortened-short ${alignClass}`}
                          dangerouslySetInnerHTML={{ __html: item.short_teaser_text}}
                        />
                        {
                          item.medium_teaser_text &&
                          <div
                            className={`teaser-field listing-item-teaser-text teaser-text shortened-medium ${alignClass}`}
                            dangerouslySetInnerHTML={{ __html: item.medium_teaser_text}}
                          />
                        }
                        {
                          item.long_teaser_text &&
                          <div
                            className={`teaser-field listing-item-teaser-text teaser-text shortened-long ${alignClass}`}
                            dangerouslySetInnerHTML={{ __html: item.long_teaser_text}}
                          />
                        }
                        <div
                          className={`teaser-field listing-item-teaser-text teaser-text shortened-raw ${alignClass}`}
                          dangerouslySetInnerHTML={{ __html: item.teaser_text}}
                        />
                      </React.Fragment> :
                      <div
                        className={`teaser-field listing-item-teaser-text teaser-text ${alignClass}`}
                        dangerouslySetInnerHTML={{ __html: item.teaser_text}}
                      />
                  }
                </div>
              </div>
            )
          }
          if (item.content_type === "oxdrupal_article" && item.author && options.display_author) {
            textBoxHtml.push(
              <div key='teaser-field-3' className="teaser-field listing-item-teaser-text teaser-text">{item.author}</div>
            )
          }
        }
      }
      // Article metadata.
      if (item.content_type === "oxdrupal_article") {
        if (!item.hide_publication_date) {
          textBoxHtml.push(
            <div key='metadata-pub-date' className="text-row text-row-metadata listing-item-pub-date">
              {
                options.metadata_labels &&
                options.metadata_labels.publication_date &&
                <div className="metadata-label">
                  {options.metadata_labels.publication_date}
                </div>
              }
              <div className="teaser-field">
                {item.formatted_publication_date.oxdrupal_listing}
              </div>
            </div>
          )
        }
      }
      // Event metadata.
      if (item.content_type === "oxdrupal_event") {
        // Date
        if (options.display_date !== undefined && options.display_date === 0) {
          textBoxHtml.push(
            <div key='text-row-1' className="text-row text-row-metadata listing-item-event-date">
              {
                options.metadata_labels &&
                options.metadata_labels.start_date &&
                <div className="metadata-label">
                  {options.metadata_labels.start_date}
                </div>
              }
              <div className="listing-item-event-date">
                {
                  item.use_custom_event_date ?
                    item.custom_event_date :
                    (item.event_date && item.formatted_event_date ? item.formatted_event_date.oxdrupal_listing : '')
                }
              </div>
            </div>
          )
        }
        // Location.
        if (item.event_location) {
          textBoxHtml.push(
            <div key='text-row-2' className="text-row text-row-metadata listing-item-event-location">
              {
                options.metadata_labels &&
                options.metadata_labels.location &&
                <div className="metadata-label">
                  {options.metadata_labels.location}
                </div>
              }
              <div className="listing-item-event-location">
                {item.event_location}
              </div>
            </div>
          )
        }
      }
      // Person metadata.
      if (item.content_type === "oxdrupal_person") {
        // Job title.
        if (item.person_job_title) {
          textBoxHtml.push(
            <div key='text-row-metadata-1' className="text-row text-row-metadata listing-item-person-job-title">
              {
                options.metadata_labels &&
                options.metadata_labels.job_title &&
                <div className="metadata-label">
                    {options.metadata_labels.job_title}
                </div>
              }
              <div className="listing-item-person-job-title">
                {item.person_job_title}
              </div>
            </div>
          )
        }
        // Affiliation.
        if (item.person_affiliation) {
          textBoxHtml.push(
            <div key='text-row-metadata-2' className="text-row text-row-metadata listing-item-person-affiliation">
              {
                options.metadata_labels &&
                options.metadata_labels.affiliation &&
                <div
                  className="metadata-label">
                  {options.metadata_labels.affiliation}
                </div>
              }
              <div className="listing-item-person-affiliation">{item.person_affiliation}</div>
            </div>
          )
        }
      }

      let textBox = []
      // Add text box if any field appearing in it has a value.
      if (
        (item.tertiary_text || item.title || item.teaser_text) ||
        (item.category_subtype && (options.display_content_subtype && options.display_style ===  'banner')) ||
        (item.content_type !== "oxdrupal_person" && (options.display_style ===  "image_gallery" && item.teaser_image_caption)) ||
        (item.content_type !== "oxdrupal_person" && item.teaser_text) ||
        (item.content_type ===  "oxdrupal_article" && (item.author || (!item.hide_publication_date && item.formatted_publication_date && item.formatted_publication_date.oxdrupal_listing))) ||
        (item.content_type ===  "oxdrupal_event" && item.event_location) ||
        (item.content_type ===  "oxdrupal_person" && (item.person_job_title || item.person_affiliation)) ||
        (item.call_to_action)
      ) {
        // Add call to action.
        if (item.call_to_action) {
        textBox.push(
          <div key='call-to-action-wrapper' className="call-to-action-wrapper">
            {
              item.cta_or_label === "call_to_action" ?
                <a
                  className="btn btn-primary btn-cta hvr-icon-grow"
                  href={item.call_to_action.url}
                  target={(item.call_to_action && item.call_to_action.attributes && item.call_to_action.attributes.target) || null}
                  aria-label={item.call_to_action ? `Link to ${item.call_to_action.url }` : null}
                >
                  {item.call_to_action && item.call_to_action.title}
                </a> :
                <div className="label-cta">{item.call_to_action && item.call_to_action.title}</div>
            }
          </div>
        )
      }
        // Text alignment.
        if (item.tertiary_text) {
          if (options.item_style_grouping ===  'tile') {
            alignClass = options.portrait_text_alignment
            if (alignClass ===  'title-center') {
              alignClass = 'text-left'
            }
          }
        }
      // Add text box.
      textBox.push(
        <div key='text-box' className={`text-box ${options.use_alt_textbox_colour && options.use_alt_textbox_colour ===  '1' ? 'alternate': ''}`}>
          <div className="text-box-row-1">
            {item.tertiary_text &&
              <div className="teaser-field listing-item-tertiary-text teaser-text">
                {
                  item.short_tertiary_text ?
                    <React.Fragment>
                      <div
                        className={`teaser-field listing-item-tertiary-text teaser-text shortened-short ${alignClass}`}
                        dangerouslySetInnerHTML={{ __html: item.short_tertiary_text}}
                      />
                      {
                        item.medium_tertiary_text &&
                        <div
                          className={`teaser-field listing-item-tertiary-text teaser-text shortened-medium  ${alignClass}`}
                          dangerouslySetInnerHTML={{ __html: item.medium_tertiary_text}}
                        />
                      }
                      {
                        item.long_tertiary_text &&
                        <div
                          className={`teaser-field listing-item-tertiary-text teaser-text shortened-long ${alignClass}`}
                          dangerouslySetInnerHTML={{ __html: item.long_tertiary_text}}
                        />
                      }
                      <div
                        className={`shortened-raw ${alignClass}`}
                        dangerouslySetInnerHTML={{ __html: item.tertiary_text}}
                      />
                    </React.Fragment> :
                    <div
                      className={`teaser-field listing-item-tertiary-text teaser-text shortened-raw  ${alignClass}`}
                      dangerouslySetInnerHTML={{ __html: item.tertiary_text}}
                    />
                }
              </div>
            }
          </div>
          {
            item.category_subtype &&
            (options.display_content_subtype || options.display_style ===  'banner') &&
            <div
              className={`text-row text-subtypes ${alignClass}`}
              dangerouslySetInnerHTML={{ __html: item.category_subtype}}
            />
          }
          <div className="text-box-row-2">
            {textBoxHtml}
          </div>
        </div>
      )
    }
      // Add text box.
      contentEl.push(
        <div key='generix-text-box' className={`text-box-wrapper ${item.content_type}`}>
          {textListingLink}
          {textBox}
        </div>
      )

      // Render.
      return (
        <div className={`${item.classes.join(' ')}`}>
          <article
            {...item.article_attributes}
            aria-label={item.title ? item.title : ""}
            className={item.article_classes}
            typeof="sioc:Item foaf:Document"
          >
            {contentEl}
            <div className="clearfix"></div>
          </article>
        </div>
      )
    }
}

export default LandscapeTextoverlayLeftBottom;
