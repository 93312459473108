import React, { useState } from 'react';
import Listing from './Listing';

const App = props  => {
  const newQ = new URLSearchParams(window.location.search)
  const [query, setQuery] = useState(window.location.search)
  // Updates the query state.
  const updateQuery = (query) => {
    setQuery(query)
  }

  // Update browser session history with adjusted parameters.
  const updateHistory = (params) => {
    // Build base URL.
    let pageURL = window.location.protocol + "//" + window.location.host + window.location.pathname
    const url = new URL(pageURL);
    url.search = params
    // Update history.
    window.history.pushState({}, '', url);
    // Update query, trigger re-render.
    updateQuery(url.search)
  }
  // Parameters.
  // - URL params.
  let params = window.location.search
  // - Page.
  let page = newQ.get('page-' + props.widgetId) || 0
  // - Sort order.
  let selectedSortOrder = newQ.get('sort-order-' + props.widgetId) || props.sortOrder || "asc"
  // - Event From Date.
  let selectedEventDateFrom = newQ.get('event-date-from-' + props.widgetId) || null
  // - Event To Date.
  let selectedEventDateTo = newQ.get('event-date-to-' + props.widgetId) || null
  // - Exposed taxonomy filters.
  let selectedFilters = {}
  if (props.taxonomyFilters) {
    props.taxonomyFilters.forEach( f => (
      selectedFilters[f.vid] = newQ.get('filter-' + f.vid + '-' + f.label.toLowerCase() + '-' + props.widgetId)
    ))
  }
  // Render.
  return (
    <Listing
      {...props}
      query={query}
      page={page}
      taxonomyPrefilters={props.taxonomyPrefilters}
      selectedFilters={selectedFilters}
      selectedSortOrder={selectedSortOrder}
      selectedEventDateFrom={selectedEventDateFrom}
      selectedEventDateTo={selectedEventDateTo}
      urlParams={decodeURI(params)}
      updateQuery={updateQuery}
      updateHistory={updateHistory}
    />
  )
}

export default App
