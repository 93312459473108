import React from 'react';
import Modal from './Modal';

export default props => {
  let item = { ...props.item }
  let options = { ...props.options }

  let itemClasses = ['views-row ']
  itemClasses.push(`views-row-${props.loopCounter}`)
  itemClasses.push(`views-row-${props.loopCounter}`)
  if (props.loopCounter % 2 === 0) {
    itemClasses.push(`views-row-even`)
  } else {
    itemClasses.push(`views-row-odd`)
  }
  if (props.loopCounter === 1) {
    itemClasses.push("views-row-first")
  }
  if (props.loopCounter === options.total_items) {
    itemClasses.push("views-row-last")
  }

  const secondRowRenderedFields = []
  const publicationPrefix = []

  if (item.publication.date) {
    secondRowRenderedFields.push(<div key="date"
      className="list-style-symplectic-publication-date"
      dangerouslySetInnerHTML={{
        __html: item.publication.date
      }}
    />)
  }
  if (item.publication.type) {
    secondRowRenderedFields.push(<div key="type"
      className="list-style-symplectic-publication-type"
      dangerouslySetInnerHTML={{
        __html: item.publication.type
      }}
    />)
  }
  if (item.publication.parentTitle) {
    secondRowRenderedFields.push(<div key="parent-title"
      className="list-style-symplectic-publication-parent-title"
      dangerouslySetInnerHTML={{
        __html: item.publication.parentTitle
      }}
    />)
  }
  if (item.publication.series_title) {
    publicationPrefix.push(<p key="series_title"
      className="publication-series-title"
      dangerouslySetInnerHTML={{
        __html: item.publication.series_title
      }}
    />)
  }
  const listItemEl = (
    <div className="publication-source-modal-trigger"
      tabIndex="0" role="button"
      data-toggle="modal"
      data-target={`#publication-source-${props.widgetId}-${props.item.publication.source.nid}`}
      data-publication-source={props.item.publication.source.nid}
      data-publication={props.item.publication.nid}
      data-widget={props.widgetId}
      onKeyUp={ev => {
        if (ev.keyCode === 13) {
          ev.target.click()
        }
      }}
    >

      <article aria-label={item.publication.source.title} className="oxdrupal-symplectic-publications-list-item listing-list-style">
        <div className="list-style-wrapper">
          <div className="list-style-text-wrapper">
            <div className="list-style-title">
              {publicationPrefix
                .reduce((acc, x) => acc === null ? [x] : [acc, ' | ', x], null)}
              <h3 dangerouslySetInnerHTML={{ __html: item.publication.source.title }} />
            </div>
            {props.showAuthorEditor &&
              <React.Fragment>
                <div className="publication-author-info"
                  dangerouslySetInnerHTML={{
                    __html: item.publication.source.authorTextEl +
                      ((item.publication.source.authorEtAl)
                        ? "<div className='publication-et-al'>et al</div>"
                        : "")
                  }}
                />
                {
                  item.publication.source.editorsEl &&
                  <div className="publication-author-info"
                    dangerouslySetInnerHTML={{
                      __html: "Edited by: " + item.publication.source.editorsEl +
                        ((item.publication.source.editorEtAl)
                          ? "<div className='publication-et-al'>et al</div>"
                          : "")
                    }}
                  />
                }
              </React.Fragment>
            }
            <div className="list-style-publication-metadata">
              {secondRowRenderedFields
                .reduce((acc, x) => acc === null ? [x] : [acc, ' | ', x], null)}
              {
                item.abstract &&
                <div className="list-style-symplectic-publication-abstract"
                  dangerouslySetInnerHTML={{
                    __html: item.abstract
                  }}
                />
              }
              {
                item.keywords &&
                <div className="list-style-symplectic-publication-keywords"
                  dangerouslySetInnerHTML={{
                    __html: item.keywords
                  }}
                />
              }
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </article>
    </div>
  )

  return (
    <div className={itemClasses.join(' ')}>
      {listItemEl}
      <div id={`publication-source-${props.widgetId}-${props.item.publication.source.nid}`}
        className="modal fade modal-wide"
        tabIndex="-1"
        role="dialog"
        aria-label={`Modal content for ${props.item.publication.source.title}`}
        aria-hidden="true">
        <Modal item={item} />
      </div>
    </div>
  )
}
