import React from 'react';
import StackedTextBox from './StackedTextBox';

class RowWithSeparatorNoImage extends React.Component {
  render() {
    let item = {...this.props.item}
    let options = {...this.props.options}

    let articleEl = (
      <article
        about={item.guid}
        typeof="sioc:Item foaf:Document"
        className={item.article_classes}
        aria-label={item.title ? item.title : ""}
      >
        <div className="text-box-wrapper">
          <div className={`text-box-and-chevron-wrapper ${!item.event_date ?  'no-info-box' : ''}`}>
            <div className="text-box">
              <StackedTextBox item={item} options={options} listingItemLink={this.props.listingItemLink}/>
            </div>
            <div className="list-style-arrow-wrapper">
              {
                item.link_url &&
                <div className="list-style-arrow fas fa-2x fa-chevron-circle-right"></div>
              }
            </div>
          </div>
        </div>
      </article>
    )
    // Render.
    return (
      <div className={`${item.classes.join(' ')}`}>
        <span className="item-style-debug" data-toggle="tooltip" title={`This is output using item style ${options.item_style}`}></span>
        <React.Fragment>
          {articleEl}
          <div className="listing-spacer listing-item-spacer"></div>
        </React.Fragment>
      </div>
    )
  }
}

export default RowWithSeparatorNoImage;
